import Icon from '@ant-design/icons';
import { Table, Tabs, Tooltip } from 'antd';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import ROOT from '../../client';
import {
	ActionBtnIcon,
	EditIcon,
	PreviousArrow,
	RightArrow,
	TrashIcon,
} from '../../utilities/IconSets';
import DeleteConfirmation from '../DeleteConfirmationPopup';

const { TabPane } = Tabs;

const itemRender = (_, type, originalElement) => {
	if (type === 'prev') {
		return (
			<a>
				<Icon component={PreviousArrow} />
			</a>
		);
	}
	if (type === 'next') {
		return (
			<a>
				<Icon component={RightArrow} />
			</a>
		);
	}
	return originalElement;
};

const pagination = {
	position: ['bottomCenter'],
	itemRender,
	locale: { items_per_page: '' },
	total: 0,
	defaultPageSize: 10,
	bordered: false,
	hideOnSinglePage: true,
};

const AllUsers = ({ setIsLoading, tableData, getUsers, setEditUser }) => {
	const { userDetails } = useSelector((store) => store.storeProps);
	const [showAction, setShowAction] = useState('');
	const [delModalState, setDelModalState] = useState({ state: false, record: '' });

	const handleUserDelete = (record) => {
		if (record.username != 'admin' && record.username != userDetails.username) {
			setDelModalState({ state: true, record: record?.username });
		}
	};

	const handleDelete = async () => {
		try {
			setIsLoading(true);
			const payload = {
				username: delModalState.record,
			};
			const res = await axios.post(`${ROOT}/api/delete-user`, payload);
			if (res) {
				setDelModalState({ state: false, record: '' });
				getUsers('delete');
			}
		} catch (error) {
			setIsLoading(false);
			console.error(error);
		}
	};

	const handleEdit = (record) => {
		if (record.username !== 'admin' && record.username !== userDetails.username) {
			setEditUser(record);
		}
	};

	const columns = [
		{
			title: 'Username',
			dataIndex: 'username',
			key: 'username',
			render: (username) => {
				return (
					<p className='usernameCol'>
						{username} {userDetails.username == username && <span>current</span>}
						{username == 'admin' && <span>default</span>}
					</p>
				);
			},
		},
		{
			title: 'Role',
			dataIndex: 'role',
			key: 'role',
		},
		{
			title: 'Access Scope',
			key: 'scope',
			width: 500,
			render: (record) => {
				return <p style={{ margin: '0' }}>{record.scope}</p>;
			},
		},
		{
			title: 'Buckets',
			dataIndex: 'buckets',
			key: 'buckets',
		},
		{
			title: '',
			key: 'actions',
			width: '10px',
			render: (record) => {
				return (
					<div
						className={`action-icon-box actionIcons`}
						onMouseEnter={() => setShowAction(record.username)}
						onMouseLeave={() => setShowAction('')}
					>
						<Icon component={ActionBtnIcon} />
						<div
							className={`action-icon-box-abs no-border ${
								record.username !== showAction ? 'hide' : 'visible'
							} `}
						>
							<Tooltip placement='top' title='Edit'>
								<Icon
									className={
										record.username == 'admin' || record.username == userDetails.username
											? 'disable'
											: ''
									}
									component={EditIcon}
									onClick={() => handleEdit(record)}
								/>
							</Tooltip>
							<Tooltip placement='top' title='Delete'>
								<Icon
									className={
										record.username == 'admin' || record.username == userDetails.username
											? 'disable'
											: ''
									}
									component={TrashIcon}
									onClick={() => handleUserDelete(record)}
								/>
							</Tooltip>
						</div>
					</div>
				);
			},
		},
	];

	return (
		<>
			<Tabs defaultActiveKey='1' className='peg_tabs'>
				<TabPane tab='All Users' key='1'>
					<Table
						className='policyStatusTable'
						columns={columns}
						dataSource={tableData}
						bordered={true}
						defaultPageSize={10}
						pagination={pagination}
					/>
				</TabPane>
			</Tabs>
			<DeleteConfirmation
				deleteAction={handleDelete}
				modalState={delModalState}
				setModalState={setDelModalState}
				text={'Do you really want to delete this user?'}
			/>
		</>
	);
};

AllUsers.propTypes = {
	isLoading: PropTypes.bool,
	setIsLoading: PropTypes.func,
	tableData: PropTypes.array,
	getUsers: PropTypes.func,
	setEditUser: PropTypes.func,
};

export default AllUsers;
