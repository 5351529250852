import Icon, { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Card, DatePicker, Input, Select, Table, Tooltip, Typography } from 'antd';
import Column from 'antd/lib/table/Column';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { getEventDetails, retryForwarding } from '../../services';
import {
	ActionBtnIcon,
	PreviousArrow,
	RefreshIcon,
	RetryForwarding,
	RightArrow,
} from '../../utilities/IconSets';
import { EVENT_STATUSES, YET_TO_BE_FORWARDED } from '../../utilities/constants';
import Loader, { Spinner } from '../Loader';
import customTost from '../Notification';
import TopHeader from '../TopHeader';
import './EventsConsole.scss';
import RequestListDrawer from './RequestListDrawer';

const { RangePicker } = DatePicker;

const { Text } = Typography;
const { Option } = Select;
const itemRender = (_, type, originalElement) => {
	if (type === 'prev') {
		return (
			<a>
				<Icon component={PreviousArrow} />
			</a>
		);
	}
	if (type === 'next') {
		return (
			<a>
				<Icon component={RightArrow} />
			</a>
		);
	}
	return originalElement;
};

const isDatepickerDisabled = (status) => {
	return status === 'all' || status === 'pending';
};

const DateFilter = (props) => {
	const { isLoading, onAction, status } = props;
	const isDisabled = isLoading || isDatepickerDisabled(status);

	const modifyMilisecs = (date, num) => {
		// Add custom milli-secs
		date.milliseconds(num);

		return date.format('YYYY-MM-DD[T]HH:mm:ss.SSSSSSZ');
	};

	const handleDateChange = (r) => {
		let range = null;
		try {
			range = {
				start: modifyMilisecs(r[0], 0),
				end: modifyMilisecs(r[1], 999),
			};
			onAction(range);
			document.activeElement.blur();
		} catch (err) {
			onAction(range);
		}
	};

	return (
		<div className='dateFilter'>
			<div
				className={`events_select_status_container date-filter-con ${isDisabled && 'disable-row'}`}
			>
				<div className='events_select_status-pre-box'>
					<Text>Date Filter</Text>
				</div>
				<RangePicker
					className='filter-range'
					disabled={isDisabled}
					format='YYYY-MM-DD HH:mm:ss'
					showTime={{ format: 'HH:mm:ss' }}
					onChange={handleDateChange}
				/>
			</div>
			{status === 'failed' && (
				<p>
					<InfoCircleOutlined /> This filter would not include events which are masking failed.
				</p>
			)}
			{(status === 'all' || status === 'pending') && (
				<p>
					<InfoCircleOutlined /> This filter will not be applicable for selected status.
				</p>
			)}
		</div>
	);
};

DateFilter.propTypes = {
	isLoading: PropTypes.bool,
	onAction: PropTypes.func,
	status: PropTypes.string,
};

const StatusInfoRow = () => {
	const StatusItem = (item) => {
		return (
			<div className='status-item'>
				<span className='title'>
					<Icon component={item.icon} />
					{item.status}
				</span>
				<span className='sub-title'>{item.title}</span>
			</div>
		);
	};

	return (
		<div className='status-info-row-wrapper'>
			<StatusItem
				status='Completed:'
				title={'Event Accepted by Process Discovery Platform'}
				icon={EVENT_STATUSES[1].icon}
			/>
			<StatusItem
				status='Forward Pending:'
				title={'In PEG queue for transmission'}
				icon={EVENT_STATUSES[3].icon}
			/>
			<StatusItem
				status='Failed:'
				title={'PEG failed to transmit to the Process Discovery Platform'}
				icon={EVENT_STATUSES[2].icon}
			/>
		</div>
	);
};

const EventsConsole = () => {
	const [showRetry, setShowRetry] = useState({});
	const [eventdata, setEventData] = useState([]);
	const [totalEvenstCount, setTotalEventsCount] = useState(0);
	const [searchEventData, setSearchEventData] = useState([]);
	const [searchValue, setSearchValue] = useState('');
	const [eventStatus, setEventStatus] = useState('all');
	const [open, setOpen] = useState(false);
	const [recordSet, setRecordSet] = useState([]);
	const [isLoading, setLoading] = useState(false);
	const [forwardingProgress, setForwardingProgress] = useState(false);
	const [filterDate, setFilterDate] = useState(null);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);

	const showDrawer = (record) => {
		setRecordSet(record);
		setOpen(true);
	};
	const [pagination, setPagination] = useState({
		position: ['bottomCenter'],
		itemRender,
		locale: { items_per_page: '' },
		total: 0,
		defaultPageSize: 100,
		bordered: false,
	});

	const [bulkKeys, setBulkKeys] = useState({});

	const fetchConsoleData = (payload, getMoreData, refresh, clickedPageNum) => {
		try {
			setSearchValue('');
			const data = [];
			setLoading(true);
			// remove dateRange from payload if date-picker is disabled
			if (isDatepickerDisabled(payload.status)) {
				payload = { ...payload, dateRange: null };
			}
			getEventDetails(payload)
				.then((res) => {
					if (res.status === 200) {
						const records = res.data?.searchRes.hits.hits;
						if (records.length) {
							records.map((event) => {
								data.push({
									key: event?._source?.screenshot_key,
									status: event?._source?.forward_status,
									event_name: `Event ${event?._source?.screenshot_key}`,
									transmission_timestamp: event?._source?.forwarded_at,
									image: event?._source?.screenshot_key,
									btns: '...',
									eventDetail: event?._source,
									eventId: event?._source?.uuid,
									is_masked: event?._source?.is_masked,
									manual_retry: false,
									sort: event?.sort,
								});
							});
						} else {
							customTost({
								type: 'success',
								message: 'No more records.',
							});
							setLoading(false);
						}
						const tableData = getMoreData ? [...eventdata, ...data] : data;
						setTotalEventsCount(res.data?.countDetails?.count || 0);
						setEventData(tableData);
						const isStatusChanged = payload?.status !== eventStatus;
						if (isStatusChanged) {
							setEventStatus(payload?.status);
						}
						const total = getMoreData ? pagination.total + data.length : data.length;

						setPagination({
							...pagination,
							total: total,
							current:
								isStatusChanged || total < pagination.total || refresh
									? 1
									: clickedPageNum
									? clickedPageNum
									: pagination.current,
						});
					}
					setLoading(false);
				})
				.catch(() => {
					setLoading(false);
				});
		} catch (err) {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchConsoleData({ status: 'all', searchAfter: [], dateRange: filterDate }, false, false);
	}, []);

	useEffect(() => {
		const filteredData = eventdata.filter((row) => {
			return row?.event_name?.toLowerCase().includes(searchValue);
		});
		setSearchEventData(filteredData);
		setPagination({
			...pagination,
			total: filteredData.length,
			current: pagination.current,
		});
	}, [searchValue]);

	const bodyEl = document.getElementById('bodyEl');
	if (bodyEl) {
		bodyEl.classList.add('dashboardBg');
	}

	const handleStatusChange = (value) => {
		setSelectedRowKeys([]);
		fetchConsoleData({ status: value, searchAfter: [], dateRange: filterDate }, false, false);
	};

	const handleSearchChange = (e) => {
		// reset pagination
		setPagination({
			...pagination,
			current: 1,
		});
		setSelectedRowKeys([]);
		setSearchValue(e?.target?.value);
	};

	const onSelectChange = (selectedRowKeys, selectedRows) => {
		const selectedEvents = selectedRows.map((d) => {
			return d.image;
		});
		setBulkKeys({ selectedEvents, selectedRowKeys });
		setSelectedRowKeys(selectedRowKeys);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
		hideSelectAll: eventStatus === 'failed' ? false : true,
		getCheckboxProps: (record) => {
			let disable = false;
			if (record?.status === 'completed' || record?.status === 'pending') {
				disable = true;
			} else {
				disable = record?.is_masked ? false : true;
			}
			return {
				disabled: disable,
			};
		},
	};

	const handleTableOnChange = async (e) => {
		if (e.current) {
			setPagination({
				...pagination,
				current: e.current,
			});
			const currentPage = e.current,
				pageSize = e.pageSize,
				total = e.total;

			// calculate the data according to the page
			const customPage = currentPage * pageSize;
			const customTotal = total;

			if (customPage >= customTotal) {
				const lastRecord = eventdata[eventdata.length - 1];
				fetchConsoleData(
					{
						status: eventStatus,
						searchAfter: lastRecord?.sort,
						dateRange: filterDate,
						lastDocStatus: lastRecord?.status,
					},
					true,
					false,
					e.current,
				);
			}
		}
	};

	const updateEvent = (rowIds) => {
		if (rowIds.length) {
			const newEventData = [...eventdata];
			rowIds.forEach((id) => {
				const rowIndex = eventdata.findIndex((row) => row.key === id);
				if (rowIndex !== -1) {
					let keylogs = newEventData[rowIndex]?.eventDetail?.raw_logs;
					if (keylogs.length) {
						keylogs = keylogs.map((row) => {
							return { ...row, forward_status: 'pending' };
						});
					}
					newEventData[rowIndex] = {
						...newEventData[rowIndex],
						status: 'pending',
						manual_retry: true,
						eventDetail: {
							...newEventData[rowIndex]?.eventDetail,
							raw_logs: keylogs,
						},
					};
				}
				if (rowIds.length === 1) {
					setRecordSet(newEventData[rowIndex]);
				}
			});

			setEventData(newEventData);
		}
	};

	const highlightRow = (record) => {
		return record?.manual_retry ? 'highlighted_row' : '';
	};

	const handleRetry = (selectedRows) => {
		if (selectedRows?.selectedEvents.length) {
			setForwardingProgress(true);
			retryForwarding({ screenshotKeys: selectedRows?.selectedEvents })
				.then(() => {
					customTost({
						type: 'success',
						message: 'Retry forwarding initiated. It will take significant time.',
					});
					setForwardingProgress(false);
					updateEvent(selectedRows?.selectedRowKeys);
					setSelectedRowKeys([]);
				})
				.catch((error) => {
					setForwardingProgress(false);
					setSelectedRowKeys([]);
					console.error(error);
				});
		}
	};

	const disableRetryForward = () => {
		if (isLoading) {
			return true;
		} else if (!selectedRowKeys.length) {
			return true;
		}
		return false;
	};

	// handle date-range-filter
	const handleDateRangeChange = (range) => {
		setSelectedRowKeys([]);
		setFilterDate(range);
		fetchConsoleData({ status: eventStatus, searchAfter: [], dateRange: range }, false, false);
	};

	return (
		<>
			{forwardingProgress && <Loader />}
			<TopHeader bucket={false} setIsLoading={() => {}} />
			<Card>
				<div className='full_width'>
					<Text className={'events_heading'}>Events Console</Text>

					<div className=' filters-wrapper'>
						<div className={`events_select_status_container ${isLoading && 'disable-row'}`}>
							<div className='events_select_status-pre-box'>
								<Text>Status</Text>
							</div>
							<Select
								disabled={isLoading}
								placeholder={'Selected Value'}
								className='events_select_status-select'
								onChange={handleStatusChange}
								value={eventStatus}
								bordered={false}
							>
								{EVENT_STATUSES.map((status) => {
									return (
										<Option key={status.value} value={status.value}>
											{status.name}
										</Option>
									);
								})}
							</Select>
						</div>

						<div
							className={`events_select_status_container searchBar ${isLoading && 'disable-row'}`}
						>
							<div className='events_select_status-pre-box'>
								<Text>Search Event Name</Text>
							</div>
							<Input
								disabled={isLoading}
								className='events_select_status-select'
								placeholder='Search here'
								allowClear
								onChange={handleSearchChange}
								value={searchValue}
							/>
						</div>

						<DateFilter
							isLoading={isLoading}
							onAction={handleDateRangeChange}
							status={eventStatus}
						/>
					</div>

					<div className='total-selected-events'>
						{selectedRowKeys.length > 0 && (
							<span>Total selected events: {selectedRowKeys.length}</span>
						)}
						{searchValue ? (
							<span>Total events: {searchEventData.length} </span>
						) : (
							<span>Total events: {isLoading ? 'calculating...' : totalEvenstCount}</span>
						)}
					</div>

					<div className='table-options-wrapper'>
						<StatusInfoRow />

						<div className='table-options'>
							<div className='retry-forwarding-header-container'>
								<Tooltip placement='top' title={'Bulk Retry'}>
									<Button
										type='link'
										className='no-border btn-grp'
										onClick={() => handleRetry(bulkKeys)}
										disabled={disableRetryForward()}
										icon={<Icon component={RetryForwarding} />}
									/>
								</Tooltip>
								<Tooltip placement='top' title={'Refresh'}>
									<Button
										type='link'
										className='no-border btn-grp'
										onClick={() =>
											fetchConsoleData(
												{ status: eventStatus, searchAfter: [], dateRange: filterDate },
												false,
												true,
											)
										}
										disabled={eventdata.length > 0 ? false : true}
										icon={<Icon component={RefreshIcon} />}
									/>
								</Tooltip>
							</div>
						</div>
					</div>

					<Table
						className='table'
						rowSelection={rowSelection}
						rowClassName={highlightRow}
						defaultPageSize={100}
						dataSource={searchValue ? searchEventData : eventdata}
						bordered
						loading={{
							indicator: <Spinner />,
							spinning: isLoading,
						}}
						pagination={pagination}
						onChange={handleTableOnChange}
						scrollToFirstRowOnChange={true}
						scroll={{ y: '60vh' }}
					>
						<Column
							title='Transmission Status'
							key='status'
							dataIndex='status'
							width={'20%'}
							render={(status, record) => {
								let renderContent = EVENT_STATUSES.filter(
									(statusObj) => statusObj.value === status,
								)?.[0];

								return (
									<div onClick={() => showDrawer(record)} className='link-box'>
										<Icon component={renderContent?.icon} />
										<Text>{renderContent?.name}</Text>
									</div>
								);
							}}
						/>
						<Column
							title='Event name'
							key='event_name'
							width={'36%'}
							dataIndex='event_name'
							render={(event_name, record) => {
								return (
									<div onClick={() => showDrawer(record)} className='link-box'>
										<Text>{event_name}</Text>
									</div>
								);
							}}
						/>
						<Column
							title='Transmission timestamp'
							dataIndex='transmission_timestamp'
							key='transmission_timestamp'
							render={(transmission_timestamp, record) => {
								const fTime = transmission_timestamp
									? moment.tz(transmission_timestamp, moment.tz.guess()).format('HH:mm:ss z')
									: '';
								const fDate = transmission_timestamp
									? moment.tz(transmission_timestamp, moment.tz.guess()).format('YYYY-MM-DD')
									: '';

								return (
									<div onClick={() => showDrawer(record)} className='link-box'>
										{fDate && fTime ? (
											<div>
												<p
													style={{
														marginBottom: '3px',
													}}
												>
													{fTime}
												</p>
												<p
													style={{
														marginBottom: '3px',
													}}
												>
													{fDate}
												</p>
											</div>
										) : (
											<>
												{record?.status === 'failed' && !record?.is_masked
													? 'Masking failed'
													: YET_TO_BE_FORWARDED}
											</>
										)}
									</div>
								);
							}}
						/>
						<Column
							title=''
							key='action'
							width={'3%'}
							className='actionCell'
							render={(_, record) => (
								<div
									className={`action-icon-box`}
									onMouseEnter={() => setShowRetry(record)}
									onMouseLeave={() => setShowRetry({})}
								>
									<Icon component={ActionBtnIcon} />
									<Button
										type='link'
										id={`${
											record?.status === 'failed' && record?.is_masked ? '' : 'action-disabled'
										}`}
										className={`action-icon-box-abs no-border `}
										style={{
											display: record !== showRetry ? 'none' : 'block',
										}}
										onClick={() => {
											if (record?.status === 'failed' && record?.is_masked) {
												handleRetry({
													selectedEvents: [record?.image],
													selectedRowKeys: [record?.key],
												});
											}
										}}
										icon={<Icon component={RetryForwarding} />}
									/>
								</div>
							)}
						/>
					</Table>
				</div>
			</Card>
			<RequestListDrawer
				open={open}
				onClose={() => setOpen(false)}
				record={recordSet}
				hideRetryIcon={() => setShowRetry({})}
				handleRetry={handleRetry}
			/>
		</>
	);
};

export default EventsConsole;
