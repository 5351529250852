export const regexValidation = (pattern, value) => {
	try {
		var regex = new RegExp(pattern, 'g');
		return regex.test(value);
	} catch (error) {
		console.error(error);
	}
};

export const removeDuplicates = (data) => {
	try {
		return [...new Set(data)];
	} catch (error) {
		console.error(error);
	}
};

export const containDuplicates = (data) => {
	try {
		let filteredArray = [...new Set(data)];
		return filteredArray.length !== data.length;
	} catch (error) {
		console.error(error);
	}
};

export const isValidJson = (fileData) => {
	try {
		const parsedData = JSON.parse(fileData);
		return parsedData;
	} catch (error) {
		return false;
	}
};
