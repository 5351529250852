import { ExclamationCircleOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import CustomButton from '../Button';
import CustomModal from '../Modal';

const DeletePolicy = (props) => {
	const { deleteModal, setDeleteModal, updatePolicy, setIsLoading } = props;

	return (
		<>
			<CustomModal
				isOpen={deleteModal.state}
				className='successModal modal_wrapper'
				onClose={() => setDeleteModal({ state: false, record: '' })}
				footer={[
					<CustomButton
						onClick={() => setDeleteModal({ state: false, record: '' })}
						key='btnClose'
						id='btnClose'
					>
						{'No, Cancel'}
					</CustomButton>,

					<CustomButton
						className='r-commit'
						onClick={() => {
							updatePolicy(deleteModal.record, 'delete');
							setIsLoading(true);
							setDeleteModal({ state: false, record: '' });
						}}
						type='primary'
						key='btndownload'
					>
						Yes, Delete
					</CustomButton>,
				]}
			>
				<div className='success_modal'>
					<ExclamationCircleOutlined className='success-modal-icon' />
					<div className='success_modal_header'>Are you sure?</div>
				</div>
			</CustomModal>
		</>
	);
};

DeletePolicy.propTypes = {
	deleteModal: PropTypes.any,
	setDeleteModal: PropTypes.func,
	updatePolicy: PropTypes.func,
	setIsLoading: PropTypes.func,
};

export default DeletePolicy;
