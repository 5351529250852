import Icon from '@ant-design/icons';
import { Button, Drawer, Image, Skeleton, Table, Typography } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { EVENT_STATUSES, YET_TO_BE_FORWARDED } from '../../utilities/constants';
import { getSignedUrl } from '../Carousel/redaction';

const { Text, Title } = Typography;

const RequestListDrawer = ({ onClose, open, record, hideRetryIcon, handleRetry }) => {
	const { envars, selectedBucket } = useSelector((store) => store.storeProps);
	const [imageUrl, setImageUrl] = useState('');
	const [eventStatus, setEventStatus] = useState({});
	const [eventTimeStamp, setEventTimeStamp] = useState('');
	const [keylogData, setKeylogData] = useState([]);
	const [isImgLoading, setIsImgLoading] = useState(false);
	let data = [];

	useEffect(() => {
		try {
			if (record?.eventDetail?.raw_logs.length) {
				record.eventDetail.raw_logs.map((keylog, i) => {
					data.push({
						key: i,
						reference: `Request ${i + 1}`,
						keylog_ref: keylog?.id,
						status: keylog?.forward_status,
						timestamp: keylog?.forwarded_at,
					});
				});
				data.push({
					key: data.length + 1,
					reference: `Screenshot`,
					keylog_ref: record?.eventId,
					status: record?.status,
					timestamp: record?.transmission_timestamp,
				});
				setKeylogData(data);
			}
			if (record?.image) {
				const getUrl = async () => {
					const urlRes = await getSignedUrl(
						envars,
						record?.image,
						selectedBucket?.bucketName || 'fiq-screenshots',
					);
					urlRes?.url && setImageUrl(urlRes.url);
				};
				getUrl();
			}

			let eStatus = EVENT_STATUSES.filter((statusObj) => statusObj.value === record.status)?.[0];
			setEventStatus(eStatus);

			if (record?.eventDetail?.forwarded_at) {
				const fTime = moment
					.tz(record.eventDetail.forwarded_at, moment.tz.guess())
					.format('HH:mm:ss.SSS z');
				const fDate = moment
					.tz(record.eventDetail.forwarded_at, moment.tz.guess())
					.format('YYYY-MM-DD');
				setEventTimeStamp(`${fTime} ${fDate}`);
			} else {
				setEventTimeStamp(
					record?.status === 'failed' && !record?.is_masked
						? 'Masking failed'
						: YET_TO_BE_FORWARDED,
				);
			}

			return () => {
				setImageUrl('');
				setIsImgLoading(false);
			};
		} catch (error) {
			console.error(error);
		}
	}, [record]);

	const columns = [
		{
			title: 'Reference',
			dataIndex: 'reference',
		},
		{
			title: 'Keylog ref',
			dataIndex: 'keylog_ref',
			width: 200,
		},
		{
			title: 'Status',
			dataIndex: 'status',
			render: (status) => {
				let renderContent = EVENT_STATUSES.filter((statusObj) => statusObj.value === status)?.[0];

				return (
					<div className='link-box'>
						{renderContent?.icon && <Icon className='status-icon' component={renderContent.icon} />}
						<Text className='status-text'>{renderContent?.name}</Text>
					</div>
				);
			},
		},
		{
			title: 'Timestamp',
			dataIndex: 'timestamp',
			render: (transmission_timestamp) => {
				const fTime = transmission_timestamp
					? moment.tz(transmission_timestamp, moment.tz.guess()).format('HH:mm:ss.SSS z')
					: '';
				const fDate = transmission_timestamp
					? moment.tz(transmission_timestamp, moment.tz.guess()).format('YYYY-MM-DD')
					: '';
				return (
					<>
						{fDate && fTime ? (
							<>
								<p
									style={{
										marginBottom: '3px',
									}}
								>
									{fTime}
								</p>
								<p
									style={{
										marginBottom: '3px',
									}}
								>
									{fDate}
								</p>
							</>
						) : (
							<>
								{record?.status === 'failed' && !record?.is_masked
									? 'Masking failed'
									: YET_TO_BE_FORWARDED}
							</>
						)}
					</>
				);
			},
		},
	];

	const handleClose = () => {
		hideRetryIcon();
		onClose();
	};

	const logRefColumns = [
		{
			title: 'Keylog Ref',
			dataIndex: 'keylog_ref',
		},
		{
			title: 'Reason for failure',
			dataIndex: 'reason',
		},
		{
			title: 'Timestamp',
			dataIndex: 'at',
			render: (at) => {
				const fTime = at ? moment.tz(at, moment.tz.guess()).format('HH:mm:ss.SSS z') : '';
				const fDate = at ? moment.tz(at, moment.tz.guess()).format('YYYY-MM-DD') : '';
				return (
					<>
						{fDate && fTime ? (
							<>
								<p
									style={{
										marginBottom: '3px',
									}}
								>
									{fTime}
								</p>
								<p
									style={{
										marginBottom: '3px',
									}}
								>
									{fDate}
								</p>
							</>
						) : (
							<>{YET_TO_BE_FORWARDED}</>
						)}
					</>
				);
			},
		},
	];

	const lpof = record?.eventDetail?.last_point_of_failure;

	return (
		<Drawer placement='right' width={'600px'} closable={false} onClose={handleClose} visible={open}>
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
				}}
			>
				<Title level={3}>Request list</Title>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						gap: '10px',
						justifyContent: 'space-between',
					}}
				>
					<Button type='ghost' className='button-ghost' onClick={handleClose}>
						Close
					</Button>
					<Button
						type='primary'
						className='button-filled'
						disabled={record?.status === 'failed' && record?.is_masked ? false : true}
						onClick={() =>
							handleRetry({ selectedEvents: [record?.image], selectedRowKeys: [record?.key] })
						}
					>
						Retry forwarding
					</Button>
				</div>
			</div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '10px',
					justifyContent: 'space-between',
					marginTop: '10px',
				}}
			>
				<div>
					<Text strong className='event-text'>
						Event: {record?.eventDetail?.screenshot_key}
					</Text>
				</div>
				<div className='text-box'>
					<Text strong>Transmission timestamp: </Text>
					<Text>{eventTimeStamp}</Text>
				</div>
				<div className='text-box'>
					<Text strong>Forwarding status: </Text>
					<Text>
						{eventStatus?.textIcon && (
							<Icon className='forwardStatus' component={eventStatus.textIcon} />
						)}
						{eventStatus?.name}
					</Text>
				</div>
				<div className='text-box'>
					<Text strong>Reason for failure: </Text>
					<Text>{record?.eventDetail?.reason_for_failure || 'NA'}</Text>
				</div>
				<div className='text-box'>
					<Text strong>Forwarding attempts left: </Text>
					<Text>{record?.eventDetail?.forward_attempts_left || 0}</Text>
				</div>
				<div className={`text-box ${lpof && 'keylog-container'} `}>
					<Text strong>Last point of failure: </Text>
					{lpof ? (
						<Table
							className='keylog-table'
							columns={logRefColumns}
							bordered
							dataSource={[{ ...lpof, key: Math.random() }]}
							size='small'
							pagination={false}
						/>
					) : (
						<Text>{'NA'}</Text>
					)}
				</div>
			</div>
			<div className='img-box'>
				<Image
					width={500}
					src={imageUrl}
					height={300}
					style={!isImgLoading && { display: 'none' }}
					placeholder={<Skeleton.Image active style={{ height: 300, width: 500 }} />}
					onLoad={() => setIsImgLoading(true)}
				/>
			</div>
			<div>
				<Table
					className='table'
					columns={columns}
					dataSource={keylogData}
					bordered
					pagination={false}
				/>
			</div>
		</Drawer>
	);
};

RequestListDrawer.propTypes = {
	onClose: PropTypes.func,
	open: PropTypes.bool,
	record: PropTypes.any,
	hideRetryIcon: PropTypes.func,
	handleRetry: PropTypes.func,
};

export default RequestListDrawer;
