import { Switch } from 'antd';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './style.scss';

const UserQueryStack = () => {
	const { userDetails } = useSelector((store) => store.storeProps);
	const [queryStack, setQueryStack] = useState([]);

	const setupQuerystack = () => {
		let qv = '';
		let op = '';
		const qs = [];
		if (userDetails?.attributes?.scopeData) {
			const scopeData = JSON.parse(userDetails?.attributes?.scopeData);
			scopeData.forEach((val) => {
				const operand = val.operand;
				const field = val.field;
				const value = val.value;

				if (operand === 'is') {
					qv = `${field} = ${value}`;
					op = '=';
				} else if (operand === 'is not') {
					qv = `${field} <> ${value}`;
					op = '<>';
				} else if (operand === 'is between') {
					qv = `${field} BETWEEN ${value}`;
					op = 'BETWEEN';
				} else if (operand === 'is not between') {
					qv = `${field} NOT BETWEEN ${value}`;
					op = 'NOT BETWEEN';
				} else if (operand === 'Like') {
					qv = `${field} LIKE ${value}`;
					op = 'LIKE';
				} else if (operand === 'Not like') {
					qv = `${field} NOT LIKE ${value}`;
					op = 'NOT LIKE';
				}
				qs.push({
					query: qv,
					operand: val.operator,
					isChecked: true,
					field,
					fieldValue: value,
					group: false,
					allowOperand: true,
					braces: '',
					operator: op,
				});
			});
		}
		setQueryStack(qs);
	};

	useEffect(() => {
		if (userDetails) {
			setupQuerystack();
		}
	}, [userDetails]);

	return (
		<>
			{queryStack.length > 0 && (
				<div className='defaultAccessScope'>
					<h4>Default Access Scope</h4>
					<ul className='queryList'>
						{queryStack.map((val, index) => {
							return (
								<li
									key={index}
									className={`${val.braces} ${
										index + 1 === queryStack.length
											? queryStack.length === 1
												? ''
												: 'endFilter'
											: index === 0
											? 'startFilter'
											: 'midFilter'
									}`}
								>
									<div key={`query_${index}`} className={`queryBlock`}>
										<div className='query'>{val.query}</div>
									</div>
									{index < queryStack.length - 1 && (
										<div key={`operand_${index}`} className={`operandOption ${index}`}>
											<Switch
												className='switch-form'
												style={{ backgroundColor: '#242323' }}
												size='small'
												key={`operand_switcher_${index}`}
												checkedChildren='AND'
												unCheckedChildren='OR'
												disabled={true}
												checked={val.operand === 'AND' ? true : false}
											/>
										</div>
									)}
								</li>
							);
						})}
					</ul>
				</div>
			)}
		</>
	);
};

UserQueryStack.propTypes = {
	setEditIndex: PropTypes.func,
	setQueryDetailArr: PropTypes.func,
	setEditFilter: PropTypes.func,
	setAddFilter: PropTypes.func,
	setUpdateSignatureFlag: PropTypes.func,
	setEditInitialVals: PropTypes.func,
	setIsValueMatch: PropTypes.func,
	setImportFile: PropTypes.func,
	setEditLoader: PropTypes.func,
};

export default UserQueryStack;
