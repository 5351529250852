import { applyMiddleware, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import { createStateSyncMiddleware } from 'redux-state-sync';
import thunk from 'redux-thunk';

import { composeWithDevTools } from 'redux-devtools-extension';
import storage from 'redux-persist/lib/storage';
import rootReducer from './rootReducers';

const reduxStateSyncConfig = { blacklist: ['persist/PERSIST'] };

const middleware = [thunk, createStateSyncMiddleware(reduxStateSyncConfig)];

const applyMiddlewareModule =
	process.env.NODE_ENV !== 'development'
		? applyMiddleware(...middleware)
		: composeWithDevTools({ trace: true, traceLimit: 50 })(applyMiddleware(...middleware));

export const store = createStore(
	persistReducer(
		{
			key: 'fortress',
			storage,
		},
		rootReducer,
	),
	applyMiddlewareModule,
);

export const persistor = persistStore(store);

// observer to get store outside react containers
export function toObservable(reduxStore) {
	return {
		subscribe({ onNext }) {
			let dispose = reduxStore.subscribe(() => onNext(reduxStore.getState()));
			onNext(reduxStore.getState());
			return { dispose };
		},
	};
}
