import { EditOutlined, SettingOutlined } from '@ant-design/icons';
import { Button, Select, Tooltip } from 'antd';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ROOT from '../../client';
import { createMC } from '../../services';
import {
	saveBuckets,
	saveMaskedBucket,
	saveMaskedFlag,
	saveSelectedBucket,
	saveUserDetails,
	setView,
} from '../../store/actions';
import { REDACTION_VIEW } from '../../utilities/constants';
import AddBucket from './addBucket';

const { Option } = Select;

const Bucket = (props) => {
	const dispatch = useDispatch();
	const { setIsLoading } = props;
	const { buckets, selectedBucket, maskedBucket, fileConfigurations, envars, username } =
		useSelector((store) => store.storeProps);
	const [bucketModalVisible, setBucketModalVisible] = useState(false);
	const [bucketsOption, setBucketsOptions] = useState([]);
	const [updateFlag, setUpdateFlag] = useState(true);
	const [disableListbox, setDisableListbox] = useState(true);
	const [disableMasked, setDisableMasked] = useState(true);
	const [defaultBucket, setDefaultBucket] = useState(
		selectedBucket && selectedBucket.bucketName ? selectedBucket.bucketName : '',
	);
	const [configureBucket, setConfigureBucket] = useState('');
	const [selectedMaskedBucket, setSelectedMaskedBucket] = useState(
		maskedBucket && maskedBucket.bucketName ? maskedBucket.bucketName : '',
	);
	const configflag =
		envars?.storageMechanism === 'minio' || envars?.storageMechanism === 'aws'
			? envars?.bucketConfigAccess
			: true;

	useEffect(() => {
		if (defaultBucket) {
			setDisableMasked(false);
		}
		// eslint-disable-next-line
	}, []);

	const storeBuckets = async (data) => {
		try {
			if (data.length) {
				let userData = '';
				let bucketOpts = [];
				dispatch(saveBuckets(data));
				const res = await axios.get(`${ROOT}/api/get-user-details?username=${username}`);
				if (res?.data) {
					userData = res.data[username];
					dispatch(saveUserDetails({ ...userData, username: username }));
				}
				if (userData && userData?.attributes?.buckets) {
					bucketOpts = JSON.parse(userData?.attributes?.buckets);
				} else {
					data.forEach((val) => {
						if (val?.bucketName) {
							bucketOpts.push(val.bucketName);
						}
					});
				}
				setBucketsOptions(bucketOpts?.length ? bucketOpts : []);
				setDisableListbox(false);
				setIsLoading(false);
			}
		} catch (error) {
			setIsLoading(false);
			setUpdateFlag(false);
			console.error(error);
		}
	};

	const getBuckets = () => {
		setIsLoading(true);
		axios
			.get(`${ROOT}/api/bucket-names`)
			.then((res) => {
				if (res?.data?.data.length) {
					storeBuckets(res?.data?.data);
				} else {
					setIsLoading(false);
				}
				setUpdateFlag(false);
			})
			.catch((error) => {
				setIsLoading(false);
				setUpdateFlag(false);
				console.error(error);
			});
	};

	useEffect(() => {
		try {
			if (updateFlag) {
				if (!defaultBucket && (fileConfigurations || configureBucket)) {
					setIsLoading(true);
				}
				if (defaultBucket && configureBucket) {
					setIsLoading(true);
				}
				if (envars?.storageMechanism === 'minio' && !envars?.bucketConfigAccess) {
					setIsLoading(true);
					const mc = createMC(envars);
					mc.listBuckets()
						.then((res) => {
							if (res) {
								const bucketData = res.map((row) => {
									return {
										bucketFolderName: '',
										bucketName: row.name,
										metaInfo: '',
									};
								});
								storeBuckets(bucketData.length ? bucketData : []);
								setIsLoading(false);
							} else {
								setIsLoading(false);
							}
						})
						.catch((error) => {
							setIsLoading(false);
							console.error(error);
						});
				} else {
					setTimeout(() => {
						getBuckets();
					}, 1000);
				}
			}
		} catch (error) {
			setIsLoading(false);
			setUpdateFlag(false);
			console.error(error);
		}
		// eslint-disable-next-line
	}, [updateFlag]);

	// Save bucket info after selected the bucket 1 and bucket 2
	const saveBucket = (bName, bucketType) => {
		try {
			if (buckets.length) {
				const bucketInfo = buckets.find((item) => item.bucketName === bName);
				if (bucketInfo) {
					if (bucketType === '1') {
						dispatch(saveSelectedBucket(bucketInfo));
						if (bName !== 'fiq-screenshots') {
							dispatch(setView('carouselView'));
						}
					} else {
						dispatch(saveMaskedBucket(bucketInfo));
						if (bName === 'forwarded') {
							dispatch(setView(REDACTION_VIEW));
						} else if (bName === 'review') {
							dispatch(setView(REDACTION_VIEW));
						} else {
							dispatch(setView('carouselView'));
						}
					}
				} else if (!bName && bucketType === '2') {
					dispatch(saveMaskedBucket(''));
				}
			}
		} catch (error) {
			console.error(error);
		}
	};

	const buckethandler = (value) => {
		try {
			if (value) {
				setDisableListbox(true);
				setDefaultBucket(value);
				setConfigureBucket('');
				saveBucket(value, '1');
				if (!selectedMaskedBucket) {
					setDisableMasked(false);
				}
			}
			if (!defaultBucket && (fileConfigurations || configureBucket)) {
				setIsLoading(false);
			}
			if (defaultBucket && configureBucket) {
				setIsLoading(false);
			}
		} catch (error) {
			console.error(error);
		}
	};
	const maskedBuckethandler = (value) => {
		try {
			if (value) {
				setDisableMasked(true);
				dispatch(saveMaskedFlag(true));
			} else {
				setDisableMasked(false);
				dispatch(saveMaskedFlag(false));
			}
			setSelectedMaskedBucket(value);
			setConfigureBucket('');
			saveBucket(value, '2');
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		if (bucketsOption?.length && !defaultBucket) {
			const findBucketOption = bucketsOption.filter((val) => val === fileConfigurations.bucketName);
			if (findBucketOption?.length) {
				buckethandler(fileConfigurations.bucketName);
			}
		}
		if (configureBucket) {
			if (selectedMaskedBucket === configureBucket) {
				maskedBuckethandler(configureBucket);
			} else {
				buckethandler(configureBucket);
			}
		}
		// eslint-disable-next-line
	}, [bucketsOption]);

	return (
		<>
			<div className='bucketInfo'>
				<div className='changeBucketWrapper'>
					<div className='bucketList'>
						<span className='bucketLabel'>
							Bucket 1: <small>(Mandatory)</small>
						</span>
						<Select
							onSelect={buckethandler}
							value={defaultBucket}
							id='bucketsList'
							showSearch
							disabled={disableListbox}
							placeholder='Select Bucket'
						>
							{bucketsOption?.length > 0 && (
								<>
									{bucketsOption.map((name) => {
										return (
											<Option key={name} value={name}>
												{name}
											</Option>
										);
									})}
								</>
							)}
						</Select>
						{selectedBucket?.bucketName && disableListbox && (
							<Tooltip placement='bottom' title='Change Bucket'>
								<Button
									className='addBucketBtn changeBucketBtn'
									icon={<EditOutlined />}
									onClick={() => setDisableListbox(false)}
								/>
							</Tooltip>
						)}
					</div>
					<div className='bucketList'>
						<span className='bucketLabel'>
							Bucket 2: <small>(Optional)</small>
						</span>
						<Select
							onSelect={maskedBuckethandler}
							value={selectedMaskedBucket}
							id='maskedbucketList'
							allowClear
							onClear={() => maskedBuckethandler('')}
							showSearch
							disabled={disableMasked}
							placeholder='Select Masked Bucket'
						>
							{bucketsOption?.length > 0 && (
								<>
									{bucketsOption.map((name) => {
										return (
											<Option key={`${name}_masked`} value={name}>
												{name}
											</Option>
										);
									})}
								</>
							)}
						</Select>
						{maskedBucket?.bucketName && disableMasked && (
							<Tooltip placement='bottom' title='Change Bucket'>
								<Button
									className='addBucketBtn changeBucketBtn'
									icon={<EditOutlined />}
									onClick={() => setDisableMasked(false)}
								/>
							</Tooltip>
						)}
					</div>
				</div>
				{configflag && (
					<div className='configureBucketWrapper'>
						<Tooltip placement='bottom' title='Configure Bucket'>
							<Button
								className='addBucketBtn'
								icon={<SettingOutlined />}
								onClick={() => setBucketModalVisible(true)}
								type='primary'
							>
								Configure
							</Button>
						</Tooltip>
					</div>
				)}
			</div>
			<AddBucket
				bucketModalVisible={bucketModalVisible}
				setBucketModalVisible={setBucketModalVisible}
				bucketsOption={bucketsOption}
				setUpdateFlag={setUpdateFlag}
				setConfigureBucket={setConfigureBucket}
			/>
		</>
	);
};

Bucket.propTypes = {
	setIsLoading: PropTypes.func,
};

export default Bucket;
