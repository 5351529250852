import { Col, InputNumber, Row } from 'antd';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import CustomButton from '../Button';
import customTost from '../Notification';
import './exportLogs.scss';

const ExportLogs = (props) => {
	const { setExportImageDisable } = props;

	const { envars } = useSelector((store) => store.storeProps);
	const { logsEndpoint } = envars;
	const [downloadLink, setDownloadLink] = useState('');
	const downloadTrigger = useRef(null);
	const [time, setTime] = useState(30);
	const [disableDownload, setDisableDownload] = useState(false);
	const [msg, setMsg] = useState({
		status: false,
		message: '',
		className: 'errorMessage',
	});
	const [isDownloading, setIsDownloading] = useState(false);

	const timeHandler = (value) => {
		try {
			setMsg({
				...msg,
				status: false,
			});
			setDisableDownload(true);
			if (value) {
				const stringfyValue = value.toString();
				const checkDot = stringfyValue.indexOf('.') !== -1 ? true : false;
				if (checkDot) {
					setMsg({
						...msg,
						status: true,
						message: 'Decimal value not allowed.',
					});
					return;
				}
				if (isNaN(value)) {
					setMsg({
						...msg,
						status: true,
						message: 'Enter numeric value only.',
					});
					return;
				}
				setTime(value);
				setDisableDownload(false);
			}
		} catch (err) {
			console.error(err);
		}
	};

	const downloadLogs = () => {
		try {
			const payload = {
				time,
			};
			if (logsEndpoint) {
				setExportImageDisable(true);
				setIsDownloading(true);
				axios({
					method: 'post',
					url: logsEndpoint,
					timeout: 600000,
					data: payload,
				})
					.then((res) => {
						if (res?.data?.status_code === 404) {
							customTost({
								type: 'success',
								message: 'Logs does not exist in the given time frame.',
							});
						}
						if (res?.data?.url && res?.data?.status_code === 200) {
							setDownloadLink(res.data.url);
							const checkHref = downloadTrigger?.current?.href ? downloadTrigger.current.href : '';
							if (checkHref) {
								downloadTrigger.current.click();
								customTost({
									type: 'success',
									message: 'File is being download.',
								});
							}
						}
						setIsDownloading(false);
						setExportImageDisable(false);
					})
					.catch((err) => {
						console.error(err);
						customTost({
							type: 'success',
							message: 'Something went wrong. Please try later.',
						});
						setIsDownloading(false);
						setExportImageDisable(false);
					});
			} else {
				customTost({
					type: 'error',
					message: 'Missing logs endpoint.',
				});
			}
		} catch (err) {
			console.error(err);
		}
	};
	return (
		<>
			<Row className='export exportLogsHeading'>
				<Col span={24}>
					<h3>Export Logs</h3>
				</Col>
			</Row>
			<Row className='timeWrapper'>
				<Col span={8}>
					<label>Enter Time</label>
				</Col>
				<Col span={16}>
					<InputNumber
						id='time_input'
						placeholder='Please enter minutes'
						min={15}
						max={10080}
						defaultValue={30}
						onChange={timeHandler}
					/>
					<div className='exportLogsExtra'>
						Given time consider as minutes. Max 10080 and Min 15 minutes.
					</div>
					{msg.status && (
						<div id='errorDiv' className={msg.className}>
							{msg.message}
						</div>
					)}
				</Col>
			</Row>
			<Row className='export-footer'>
				<Col span={18} className='export-buttons'>
					<CustomButton
						id='btnDownloadLogs'
						isDisabled={disableDownload}
						isLoading={isDownloading}
						type='primary'
						onClick={downloadLogs}
					>
						Download
					</CustomButton>
					<a ref={downloadTrigger} href={downloadLink} style={{ display: 'none' }}>
						Export Logs
					</a>
				</Col>
			</Row>
		</>
	);
};

ExportLogs.propTypes = {
	setExportImageDisable: PropTypes.func,
};

export default ExportLogs;
