export const isValidJson = (string) => {
	try {
		JSON.parse(string);
	} catch (error) {
		return false;
	}
	return true;
};

export const getAllowedFiltersForDelete = () => ['application', 'user', 'machine'];
