/* eslint-disable no-unused-vars */
import Icon from '@ant-design/icons';
import { Form, Layout, Tabs, Tooltip } from 'antd';
import Text from 'antd/lib/typography/Text';
import axios from 'axios';
import { flatten } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Carousel as CarouselSlider } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import ROOT from '../../client';
import { saveCarouselSlots, saveImageKeys, saveRedactionView } from '../../store/actions';
import { RightArrowIcon } from '../../utilities/IconSets';
import { EVENT_STATUSES, REDACTION_VIEW } from '../../utilities/constants';
import CustomButton from '../Button';
import Loader from '../Loader';
import customTost from '../Notification';
import RenderImage from './RenderImage';
import './carousel.scss';
import Redaction from './redaction';
import RegexRedaction from './regex';
const { Content, Sider } = Layout;

const REGEX_VIEW = 'regexView';

const getFormatedDate = (date) => {
	if (!date) return 'NA';
	return moment.tz(date, moment.tz.guess()).format('YYYY-MM-DD HH:mm:ss z');
};

const DeleteText = () => (
	<div className='delete-text'>
		<Text>Delete Mode</Text>
	</div>
);

let cancelTokenOcrData;

const Carousel = (props) => {
	const dispatch = useDispatch();
	const {
		mappingImageKey,
		imageLoadCount,
		isConfigureFieldEnable,
		selectedBucket,
		carouselSlots,
		imageKeys,
		maskedBucket,
		view,
		envars,
		isDeleteModeOn,
		redactionView,
		mappingProps,
	} = useSelector((store) => store.storeProps);
	const { queryData, selectedOption, setIsLoading, setIsDataLoading, minioClient } = props;
	const [toggleCarousel, setToggleCarousel] = useState(false);
	const [imgIndex, setImgIndex] = useState(0);
	const [carouselData, setCarouselData] = useState([]);
	const [lastIndex, setLastIndex] = useState(0);
	const [currentSlot, setcurrentSlot] = useState(0);
	const initialLoadCount = 5;
	const carouselSize = 100;
	const [selectedSlide, setselectedSlide] = useState(0);
	const [ocrData, setOcrData] = useState([]);
	const [collapsed, setCollapsed] = useState(false);
	const [imageText, setImageText] = useState({
		status: false,
		bbStatus: false,
		text: '',
		message: 'Please click over the image to find the text.',
		top: 0,
		left: 0,
		width: 0,
		height: 0,
	});
	const [gettingData, setGettingData] = useState(false);
	const [isRedacting, setRedacting] = useState(false);
	const isSkipGlobalLoading = useRef(false);
	const [previewView, setPreviewView] = useState({
		enable: false,
		state: false,
		data: '',
		singlePreview: false,
	});
	const [previewLoading, setPreviewLoading] = useState(false);
	const [redImage, setRedImage] = useState('');
	const [form] = Form.useForm();
	const [currentWords, setCurrentWords] = useState({
		pass: { addList: [], removeList: [] },
		block: { addList: [], removeList: [] },
	});
	const [currentRegex, setCurrentRegex] = useState('');
	const [redactionSettings, setRedactionSettings] = useState({
		symspell__edit_distance: 0,
		cleaning: false,
	});
	const [redactionResult, setRedactionResult] = useState([]);
	const [ocr, setOcr] = useState([]);
	const [initialValues, setInitialValues] = useState([]);
	const [commitDisable, setCommitDisable] = useState(true);

	const getCurrentImage = () => {
		const imgData = carouselData[currentSlot][imgIndex];
		return imgData?.data?.screenshot_key || '';
	};

	useEffect(() => {
		// Fetch and set Forwarding form values
		try {
			axios.get(`${ROOT}/api/get-deployment-envars`).then((res) => {
				if (res?.data?.envData !== {}) {
					res.data.envData.reduce((modArr, item) => {
						if (item?.name) {
							modArr[item.name] = item.value ? item.value : '';
						}
						return modArr;
					}, {});
				}
				const data = res?.data?.envData;
				setInitialValues(data);
				const cleaning = data.find((i) => i.name === 'cleaning');
				const symspell = data.find((i) => i.name === 'symspell__edit_distance');
				// const review_mode = data.find((i) => i.name === 'review_mode');
				const redactionSettingValues = {
					// set defautl values in form if not present in res
					cleaning: JSON.parse(cleaning?.value) || false,
					symspell__edit_distance: JSON.parse(symspell?.value) || 0,
					// review_mode: JSON.parse(review_mode?.value),
				};
				setRedactionSettings(redactionSettingValues);
			});
		} catch (err) {
			return;
		}

		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		isSkipGlobalLoading.current = isRedacting;
	}, [isRedacting]);

	useEffect(() => {
		window.addEventListener('keydown', clickHandler);
		return () => window.removeEventListener('keydown', clickHandler);
	});

	// call fetch image whenever user searches query
	useEffect(() => {
		let unmount = false;
		try {
			if (!unmount) {
				fetchImage(0, initialLoadCount);
				setToggleCarousel(!toggleCarousel);
				dispatch(saveCarouselSlots(['1']));
			}
		} catch (error) {
			console.error(error);
		}
		return () => {
			unmount = true;
		};
		// eslint-disable-next-line
	}, [queryData]);

	useEffect(() => {
		setRedactionResult([]);
		try {
			// reset real-time image on view change
			setRedImage('');
			setImageText({
				status: view === REDACTION_VIEW || view === 'masked' ? true : false,
				bbStatus: false,
				text: '',
				message: 'Please click over the image to find the text.',
				top: 0,
				left: 0,
				width: 0,
				height: 0,
			});
		} catch (error) {
			console.error(error);
		}
		// eslint-disable-next-line
	}, [view]);

	useEffect(() => {
		if (imgIndex === 0 && carouselData.length) {
			getOcrData(0, 0);
		}
	}, [carouselData]);

	useEffect(() => {
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const [windowDimensions, setWindowDimensions] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	});
	const handleResize = () => {
		setWindowDimensions({
			width: window.innerWidth,
			height: window.innerHeight,
		});
	};

	useEffect(() => {
		renderBoundingBox(ocr);
	}, [windowDimensions]);

	const renderBoundingBox = (ocr) => {
		try {
			if (ocr.length) {
				let sNode = document?.querySelector?.(`.compareSlider`);
				let childNodes = sNode?.childNodes ? sNode.childNodes : [];
				let imgNode;
				let naturalWidth = 0;
				let naturalHeight = 0;
				let newOcr = [];
				if (childNodes.length) {
					imgNode = childNodes[0];
					naturalWidth = imgNode.naturalWidth;
					naturalHeight = imgNode.naturalHeight;
					const imgBoundingBox = imgNode.getBoundingClientRect();
					let scaleImgLeft = 0;
					let scaleImgTop = 0;
					let scaleWidth = 0;
					let scaleHeight = 0;
					newOcr = ocr.map((val) => {
						let coords =
							val && val.vertices && val.vertices.coordinates.length
								? val.vertices.coordinates[0]
								: [];
						coords = coords.length ? coords[0] : [];
						const coordX = coords.length ? coords[0] : 0;
						const coordY = coords.length ? coords[1] : 0;
						if (imgBoundingBox) {
							scaleImgLeft = Math.floor((imgBoundingBox.width / naturalWidth) * coordX);
							scaleImgTop = Math.floor((imgBoundingBox.height / naturalHeight) * coordY);
							scaleWidth = Math.floor((imgBoundingBox.width / naturalWidth) * val.width);
							scaleHeight = Math.floor((imgBoundingBox.height / naturalHeight) * val.height);
						}
						return {
							left: scaleImgLeft,
							top: scaleImgTop,
							mode: val.mode,
							width: scaleWidth,
							height: scaleHeight,
							text: val.description,
						};
					});
				}
				setRedactionResult(newOcr);
			} else {
				setRedactionResult([]);
			}
			setIsLoading(false);
			setRedacting(false);
			setPreviewLoading(false);
		} catch (error) {
			setIsLoading(false);
			setRedacting(false);
			setPreviewLoading(false);
		}
	};

	const handleBoundingBox = (word) => {
		setImageText({
			...imageText,
			status: true,
			text: word,
			message: 'You clicked on this text:',
		});
	};

	const splitArrIntoChunks = (array) => {
		let chunkSize = carouselSize;
		let i,
			j,
			accum = [];

		for (i = 0, j = array.length; i < j; i += chunkSize) {
			accum = [...accum, array.slice(i, i + chunkSize)];
		}

		return accum;
	};

	const [isPending, setIsPending] = useState(true);

	// fetching images from server
	const fetchImage = async (index, imgCount) => {
		try {
			if (queryData.length && selectedBucket) {
				const endIndex =
					queryData.length >= index + Number(imgCount)
						? index + Number(imgCount)
						: queryData.length;
				setLastIndex(endIndex);
				const apiPath = `${ROOT}/api/image_carousel`;
				let mappingKeys = queryData.map((data) => data[mappingImageKey]);
				const slicedKeys = mappingKeys.slice(index, endIndex);
				if (slicedKeys?.length) {
					setIsPending(true);
					setIsDataLoading(true);
					const payLoad = {
						originalImagesDetails: {
							screenshotKeys: slicedKeys,
							bucketName: selectedBucket?.bucketName ? selectedBucket.bucketName : '',
							metaInfo: selectedBucket?.metaInfo ? selectedBucket.metaInfo : '',
							bucketFolderName: selectedBucket?.bucketFolderName
								? selectedBucket.bucketFolderName
								: '',
						},
						maskedImagesDetails: maskedBucket
							? {
									screenshotKeys: slicedKeys,
									bucketName: maskedBucket?.bucketName ? maskedBucket.bucketName : '',
									metaInfo: maskedBucket?.metaInfo ? maskedBucket.metaInfo : '',
									bucketFolderName: selectedBucket?.bucketFolderName
										? selectedBucket.bucketFolderName
										: '',
							  }
							: null,
					};
					if (envars?.storageMechanism === 'minio' && minioClient?.firstBucket) {
						let promises = slicedKeys.map((imageName) => {
							return minioClient.firstBucket
								.presignedGetObject(selectedBucket.bucketName, imageName)
								.then((res) => {
									return {
										screenshotKey: imageName,
										url: res,
										error: false,
									};
								})
								.catch(() => {
									return {
										screenshotKey: imageName,
										url: null,
										error: true,
									};
								});
						});
						Promise.all(promises)
							.then((results) => {
								if (results) {
									const allUrls = {
										originalImagesUrls: results,
									};
									if (maskedBucket && minioClient?.secondBucket) {
										let maskPromises = slicedKeys.map((imageName) => {
											return minioClient.secondBucket
												.presignedGetObject(maskedBucket.bucketName, imageName)
												.then((res) => {
													return {
														screenshotKey: imageName,
														url: res,
														error: false,
													};
												})
												.catch(() => {
													return {
														screenshotKey: imageName,
														url: null,
														error: true,
													};
												});
										});
										Promise.all(maskPromises)
											.then((maskResults) => {
												if (maskResults) {
													const existErrorB2 = maskResults.find((s) => s.error === true);
													if (existErrorB2) {
														customTost({
															type: 'error',
															message: 'Please check your second bucket configuration.',
														});
													}
													const existErrorB1 = results.find((s) => s.error === true);
													if (existErrorB1) {
														customTost({
															type: 'error',
															message: 'Please check your first bucket configuration.',
														});
													}
													allUrls.maskedImagesUrls = maskResults;
													getImages(allUrls, index, endIndex);
												}
											})
											.catch((e) => {
												console.log('promise catch: ', e);
												console.error(e);
												setIsDataLoading(false);
											});
									} else {
										const existError = results.find((s) => s.error === true);
										if (existError) {
											customTost({
												type: 'error',
												message: 'Please check your first bucket configuration.',
											});
										}
										getImages(allUrls, index, endIndex);
									}
								}
							})
							.catch((e) => {
								setIsDataLoading(false);
								console.error(e);
							});
					} else {
						await axios
							.post(apiPath, payLoad)
							.then((response) => {
								if (response.data) {
									getImages(response.data, index, endIndex);
								}
							})
							.catch((error) => {
								setIsDataLoading(false);
								if (error?.response?.data?.message) {
									customTost({
										type: 'error',
										message: error.response.data.message,
									});
								}
							});
					}
				} else {
					setIsDataLoading(false);
					setIsPending(false);
				}
				if (index === 0) {
					setselectedSlide(0);
					setImgIndex(0);
				}
			} else {
				handlePageLoadingState();
				setIsDataLoading(false);
			}
		} catch (error) {
			handlePageLoadingState();
			customTost({
				type: 'error',
				message: 'Something went wrong. Please try later.',
			});
			console.error(error);
		}
	};

	const getOcrData = (index, slotIndex) => {
		try {
			if (envars.deployEnv !== 'aws') {
				if (previewView?.enable) {
					setPreviewLoading(true);
				}
				setOcrData([]);
				if (typeof cancelTokenOcrData != typeof undefined) {
					cancelTokenOcrData.cancel('Operation canceled due to new request.');
				}
				cancelTokenOcrData = axios.CancelToken.source();
				if (view === REDACTION_VIEW || view === 'masked') {
					setGettingData(true);
					if (
						carouselData.length &&
						carouselData[slotIndex].length &&
						carouselData[slotIndex][index]?.data?.screenshot_key
					) {
						const screenshotKey = carouselData[slotIndex][index].data.screenshot_key;
						setImageText({
							...imageText,
							status: true,
							bbStatus: false,
							text: '',
							message: 'Fetching OCR data. Please wait...',
						});
						axios
							.get(`${ROOT}/api/get-ocr-data?screenshotKey=${screenshotKey}`, {
								cancelToken: cancelTokenOcrData.token,
							})
							.then((res) => {
								setGettingData(false);
								setImageText({
									...imageText,
									status: true,
									bbStatus: false,
									text: '',
									message: res?.data?.length
										? 'Please click over the image to find the text.'
										: 'OCR data is not available when masking is in progress.',
								});
								if (res?.data?.length) {
									setOcrData(res.data);
									// hit redaction API on image change (when view is redaction)
								} else {
									setPreviewLoading(false);
								}
								if (view === REDACTION_VIEW) {
									// reset real-time-image on image change
									setRedImage('');
									if (previewView?.data) {
										setPreviewView({
											enable: true,
											state: true,
											data: previewView?.data,
											singlePreview: previewView?.singlePreview,
										});
									} else if (previewView?.enable) {
										form.submit();
									}
								}
							})
							.catch((err) => {
								setPreviewLoading(false);
								if (axios.isCancel(err))
									setImageText({
										...imageText,
										status: true,
										bbStatus: false,
										text: '',
										message: 'Something went wrong. Please wait or try again.',
									});
							});
					}
				}
			}
		} catch (err) {
			console.error(err);
		}
	};

	const getImages = (responseUrls, index, endIndex) => {
		try {
			if (responseUrls) {
				let updatedUrls = [];
				responseUrls.originalImagesUrls.forEach((values, idx) => {
					const url = values && values.url && values.url !== null ? values.url : './no-image.png';
					const screenshotKey =
						values && values.screenshotKey && values.screenshotKey !== null
							? values.screenshotKey
							: '';
					let maskedUrl = '';
					let maskedScreenshotKey = '';

					if (responseUrls?.maskedImagesUrls?.[idx]) {
						const maskedVal = responseUrls.maskedImagesUrls[idx];
						maskedUrl =
							maskedVal && maskedVal.url && maskedVal.url !== null
								? maskedVal.url
								: './no-image.png';
						maskedScreenshotKey =
							maskedVal && maskedVal.screenshotKey && maskedVal.screenshotKey !== null
								? maskedVal.screenshotKey
								: '';
					}

					updatedUrls.push({
						url,
						screenshotKey,
						maskedUrl,
						maskedScreenshotKey,
					});
				});
				let carouselAlldata = [...carouselData];
				let allKeys = imageKeys?.length ? [...imageKeys] : [];
				allKeys = flatten(allKeys);
				carouselAlldata = flatten(carouselAlldata);
				for (let i = index, j = 0; i < endIndex || j < updatedUrls.length; i++, j++) {
					carouselAlldata.push({
						index: i,
						data: queryData[i],
						url: updatedUrls[j]?.url,
						maskedUrl: updatedUrls[j]?.maskedUrl,
					});
					if (updatedUrls[j]?.screenshotKey) {
						allKeys.push(updatedUrls[j]?.screenshotKey);
					}
				}
				const allKeysChunks = splitArrIntoChunks(allKeys);
				dispatch(saveImageKeys(allKeysChunks));
				setCarouselData(splitArrIntoChunks(carouselAlldata));
			}
			setTimeout(() => {
				handlePageLoadingState();
				setIsDataLoading(false);
			}, 3000);
		} catch (error) {
			console.error(error);
		}
	};

	/* istanbul ignore next */
	const handleImageLoad = (event, eventName, eventFlag) => {
		let imgnewIndex = carouselSize * currentSlot + imgIndex;
		let newEvent = carouselSize * currentSlot + event;
		if (lastIndex === initialLoadCount) {
			fetchImage(lastIndex, imageLoadCount);
		} else {
			if (eventName === 'next' && lastIndex === imgnewIndex + Number(imageLoadCount)) {
				fetchImage(lastIndex, imageLoadCount);
			}
			if ((eventName === 'bulletDown' || eventName === 'bulletUp') && lastIndex === newEvent + 1) {
				fetchImage(lastIndex, imageLoadCount);
			}
		}
		if (eventFlag) setselectedSlide(99);
		setImgIndex(event);
	};

	/* istanbul ignore next */
	const handleChange = (event) => {
		try {
			setRedactionResult([]);
			let eventName = '';
			if (imgIndex === 0 && event === 0 && currentSlot === 0) return;
			if (imgIndex < event) {
				eventName = imgIndex + 1 === event ? 'next' : 'bulletDown';
			} else {
				eventName = imgIndex - 1 === event ? 'prev' : 'bulletUp';
			}
			if (imgIndex === 99 && eventName !== 'prev') {
				setselectedSlide(0);
			}
			if (event >= carouselSize - 1 && imgIndex === 99) {
				if (isPending) {
					if (carouselSlots?.length) {
						const newSlot = currentSlot + 2;
						const savedSlots = [...carouselSlots];
						const isExist = savedSlots.includes(newSlot);
						if (!isExist) {
							savedSlots.push(newSlot);
							dispatch(saveCarouselSlots(savedSlots));
						}
					}
					setcurrentSlot(currentSlot + 1);
					setToggleCarousel(!toggleCarousel);
					handleImageLoad(0, eventName, false);
					getOcrData(0, currentSlot + 1);
				}
			} else if (
				event <= 0 &&
				imgIndex === 0 &&
				currentSlot !== 0 &&
				eventName !== 'next' &&
				eventName !== 'bulletDown'
			) {
				if (!isPending) {
					setIsPending(true);
				}
				setcurrentSlot(currentSlot - 1);
				setToggleCarousel(!toggleCarousel);
				handleImageLoad(99, eventName, true);
				getOcrData(99, currentSlot - 1);
			} else if (lastIndex !== imgIndex + 1) {
				handleImageLoad(event, eventName, false);
				getOcrData(event, currentSlot);
			} else {
				setImgIndex(event);
				getOcrData(event, 0);
				setIsDataLoading(false);
			}
		} catch (error) {
			console.error(error);
		}
	};

	// move images in carousel by keyboard keys
	/* istanbul ignore next */
	const clickHandler = (e) => {
		try {
			if (e?.target?.nodeName === 'DIV' || e?.target?.nodeName === 'BUTTON') {
				if (e.ctrlKey) {
					if (e.keyCode === 38) {
						e.preventDefault();
						return document.getElementsByClassName('control-dots')[0]?.firstElementChild?.click();
					}
				} else {
					if (e.keyCode === 38) {
						e.preventDefault();
						return document.getElementsByClassName('control-dots')[0]?.firstElementChild?.click();
					}
					if (e.keyCode === 40) {
						e.preventDefault();
						return document.getElementsByClassName('control-dots')[0]?.lastElementChild?.click();
					}
				}
			}
		} catch (error) {
			console.error(error);
		}
	};

	const formatStatus = (currentItem, total) => {
		return (
			<>
				<strong>Slot {currentSlot + 1}:</strong> &nbsp;{currentItem} of {total}
			</>
		);
	};

	const handleRedactionProcess = (img) => {
		setRedImage(img);
	};

	const handlePageLoadingState = () => {
		if (view === REDACTION_VIEW && isSkipGlobalLoading.current) {
			return;
		}

		setIsLoading(false);
	};

	const renderFieldValue = (key, value) => {
		let fieldValue = 'NA';
		if (value || typeof value === 'number') fieldValue = value;
		if (value === true) fieldValue = 'Yes';
		if (value === false) fieldValue = 'No';
		if (value && key === 'forward_status')
			fieldValue = EVENT_STATUSES.filter((statusObj) => statusObj.value === value)?.[0]?.name;

		if (value && mappingProps[key]?.type === 'date') {
			const date = getFormatedDate(value);
			fieldValue = date;
		}

		return (
			<>
				<strong>{key}:</strong> {fieldValue}
			</>
		);
	};

	const renderCarousel = () => {
		// modifyCssClass({ type: c.view, parentClass: '.carousel-status', newClass: 'c-s2' });

		return (
			carouselData.length > 0 && (
				<div className='carouselWrapper'>
					<div className='c-wrapper'>
						{/* {reviewMode && view === redactionView && maskedBucket?.bucketName === 'review' && (
							<div className='reviewFlag'>Review</div>
						)} */}

						{imageText.status && envars.deployEnv !== 'aws' && (
							<div className='selectedText'>
								{imageText.message} <strong>{imageText.text}</strong>
								<span onClick={() => setImageText({ ...imageText, status: false })}>
									<img src='./close-icon.svg' />
								</span>
							</div>
						)}
						{isDeleteModeOn && <DeleteText />}
						<CarouselSlider
							className={`carousel ${isConfigureFieldEnable ? '' : 'noFieldSelected'} ${
								isDeleteModeOn && 'delete-border'
							}`}
							onChange={(event) => handleChange(event)}
							showThumbs={false}
							key={toggleCarousel}
							autoFocus={true}
							selectedItem={selectedSlide}
							useKeyboardArrows={previewLoading ? false : true}
							statusFormatter={formatStatus}
							infiniteLoop={false}
							renderArrowNext={(onClickHandler) => {
								return (
									<CustomButton
										onClick={onClickHandler}
										className='right-arrow-button carousel-btn'
									>
										Next
									</CustomButton>
								);
							}}
							renderArrowPrev={(onClickHandler) => {
								return (
									<CustomButton onClick={onClickHandler} className='left-arrow-button carousel-btn'>
										Previous
									</CustomButton>
								);
							}}
						>
							{carouselData[currentSlot] &&
								carouselData[currentSlot].map((res, ind) => {
									return (
										<div
											className={`img-wrapper img-wrapper_${ind} regexViewWrapper`}
											key={`img-wrapper_${ind}`}
										>
											{isConfigureFieldEnable && (
												<div className={`img-data img-data_${ind}`} key={`img-data_${ind}`}>
													{selectedOption.map((opt, i) => {
														if (opt.isChecked === true) {
															return (
																<div
																	style={{
																		wordBreak: `${opt.value === 'url' ? 'break-all' : 'unset'}`,
																	}}
																	className='fieldInfo'
																	key={`dataOptVal_${i}`}
																>
																	<div className='fieldValue'>
																		{renderFieldValue(opt.value, res?.data?.[opt.value])}
																	</div>
																</div>
															);
														} else {
															return null;
														}
													})}
												</div>
											)}
											<div className='imgBlock' key={`imgBlock_${ind}`}>
												{redactionResult.length > 0 &&
													redactionResult.map((ocrInfo, i) => {
														return (
															<>
																<Tooltip
																	key={i}
																	placement='top'
																	title={`${
																		ocrInfo.mode === 'replace' || ocrInfo.mode === 'replace_system'
																			? 'Replaced'
																			: 'Redacted'
																	} By ${
																		ocrInfo.mode === 'regex'
																			? 'Regex'
																			: ocrInfo.mode === 'replace'
																			? 'User Input'
																			: ocrInfo.mode === 'replace_system'
																			? 'System Generated'
																			: 'Word'
																	}`}
																>
																	<div
																		onClick={() => handleBoundingBox(ocrInfo.text)}
																		className={`${
																			ocrInfo.mode === 'replace' ||
																			ocrInfo.mode === 'replace_system'
																				? 'replaceBoundingBox'
																				: 'imgBoundingBox'
																		}`}
																		style={{
																			left: ocrInfo.left,
																			top: ocrInfo.top,
																			borderStyle: ocrInfo.mode === 'regex' ? 'dotted' : 'solid',
																			width: ocrInfo.width,
																			height: ocrInfo.height,
																		}}
																	></div>
																</Tooltip>
															</>
														);
													})}
												<RenderImage
													imageData={
														view === REDACTION_VIEW
															? { ...res, maskedUrl: redImage?.url || res.maskedUrl }
															: res
													}
													setImageText={setImageText}
													imageText={imageText}
													ocrData={ocrData}
													gettingData={gettingData}
													imgIndex={imgIndex}
												/>
											</div>
											{/* <img style={{ display: 'none' }} src={res.url} alt='' /> */}
										</div>
									);
								})}
						</CarouselSlider>
					</div>
				</div>
			)
		);
	};

	return (
		<>
			{previewLoading && <Loader />}
			{view === REDACTION_VIEW ? (
				<>
					<div className='redaction-view'>
						<Text className='redaction-view-text'>Real Time Redaction</Text>
					</div>
					<div className='redaction-view-wrapper'>
						<Layout className='redaction-layout'>
							<Sider
								trigger={null}
								collapsible
								collapsed={collapsed}
								width={400}
								className={`redaction-layout-sider redaction-layout-border`}
							>
								<div className='redaction-view-left'>
									<Tabs
										activeKey={view === redactionView ? REDACTION_VIEW : REGEX_VIEW}
										onTabClick={(view) => {
											dispatch(saveRedactionView(view));
											setPreviewView({
												enable: false,
												state: false,
												data: '',
												singlePreview: false,
											});
											setCommitDisable(true);
										}}
									>
										<Tabs.TabPane tab='Words' key={REDACTION_VIEW}>
											<Redaction
												form={form}
												getSelectedImg={getCurrentImage}
												handleRedactionProcess={handleRedactionProcess}
												setPreviewLoading={setPreviewLoading}
												previewLoading={previewLoading}
												isLocLoading={isRedacting}
												setLocLoading={setRedacting}
												setCurrentWords={setCurrentWords}
												currentRegex={currentRegex}
												setRedactionSettings={setRedactionSettings}
												redactionSettings={redactionSettings}
												renderBoundingBox={renderBoundingBox}
												setRedactionResult={setRedactionResult}
												setOcr={setOcr}
												ocrData={ocrData}
												initialValues={initialValues}
												setPreviewView={setPreviewView}
												setCommitDisable={setCommitDisable}
												commitDisable={commitDisable}
											/>
										</Tabs.TabPane>
										<Tabs.TabPane tab='Regex' key={REGEX_VIEW}>
											<RegexRedaction
												getSelectedImg={getCurrentImage}
												handleRedactionProcess={handleRedactionProcess}
												previewView={previewView}
												setPreviewView={setPreviewView}
												setPreviewLoading={setPreviewLoading}
												previewLoading={previewLoading}
												currentWords={currentWords}
												setCurrentRegex={setCurrentRegex}
												redactionSettings={redactionSettings}
												renderBoundingBox={renderBoundingBox}
												setRedactionResult={setRedactionResult}
												setOcr={setOcr}
												ocrData={ocrData}
												initialValues={initialValues}
												setCommitDisable={setCommitDisable}
												commitDisable={commitDisable}
											/>
										</Tabs.TabPane>
									</Tabs>
									<div
										className='redaction-left-outlined'
										style={collapsed === true ? { transform: 'rotate(180deg)' } : null}
									>
										<Tooltip title={collapsed ? 'Expand' : 'Collapse'} placement='right'>
											<Icon
												component={RightArrowIcon}
												className='redaction-left-outlined-icon'
												onClick={() => {
													setCollapsed(!collapsed);
													if (ocr.length) {
														setIsLoading(true);
														setRedactionResult([]);
														setTimeout(() => {
															renderBoundingBox(ocr);
														}, 1000);
													}
												}}
											/>
										</Tooltip>
									</div>
								</div>
							</Sider>
							<Layout style={{ minWidth: '500px' }}>
								<Content>{renderCarousel()}</Content>
							</Layout>
						</Layout>
					</div>
				</>
			) : (
				renderCarousel()
			)}
		</>
	);
};

Carousel.propTypes = {
	queryData: PropTypes.array,
	selectedOption: PropTypes.array,
	setIsLoading: PropTypes.func,
	setIsDataLoading: PropTypes.func,
	minioClient: PropTypes.any,
};

export default Carousel;
