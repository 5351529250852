import crypto from 'crypto-browserify';
export const encryption = (value) => {
	return new Promise((resolve) => {
		const ivBuf = Buffer.from('phantom@security');
		const securityBuf = Buffer.from('phantom@securityphantom@security');
		const algo = 'aes-256-cbc';
		const cipher = crypto.createCipheriv(algo, securityBuf, ivBuf);
		let cred = cipher.update(value, 'utf8', 'hex');
		cred += cipher.final('hex');

		resolve(cred);
	});
};
