/* eslint-disable */

import { InfoCircleFilled } from '@ant-design/icons';
import { DatePicker, Form, Radio, Select } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { dateFormat } from '../../utilities/constants';
import { days } from '../../utilities/days';
import CustomButton from '../Button';
import CustomModal from '../Modal';

const { Option } = Select;

const UpdatePolicy = (props) => {
	const [form] = Form.useForm();
	const { updateModal, setUpdateModal, updatePolicy, setIsLoading } = props;
	const [day, setDay] = useState(1);
	const [week, setWeek] = useState('monday');
	const [weekOption, setWeekOption] = useState('first');
	const [preserveDay, setPreserveDay] = useState(15);
	const [isValueChanged, setIsValueChanged] = useState(false);

	const onSubmit = async (values) => {
		try {
			setIsLoading(true);
			values['trigger_day'] = {
				day:
					values.trigger_day == 'day'
						? day
						: values.trigger_day == 'week' && weekOption == 'first'
						? 1
						: 5,
				weekday: values.trigger_day == 'week' ? week : '',
			};
			values['starting_date'] = moment(values.starting_date).format(dateFormat);
			values['data_retention'] = preserveDay;
			values['id'] = updateModal?.record?.id;
			setUpdateModal({ state: false, record: '' });
			updatePolicy(values, 'edit');
		} catch (error) {
			console.error(error);
		}
	};

	const handleDayChange = (selectedDay) => {
		setIsValueChanged(true);
		setDay(selectedDay);
	};

	const handleWeekOptionChange = (selectedWeekOption) => {
		setIsValueChanged(true);
		setWeekOption(selectedWeekOption);
	};

	const handleWeekChange = (selectedWeek) => {
		setIsValueChanged(true);
		setWeek(selectedWeek);
	};

	const handlePreserveChange = (selectedPreserveDays) => {
		setIsValueChanged(true);
		setPreserveDay(selectedPreserveDays);
	};

	useEffect(() => {
		if (updateModal?.state && updateModal?.record) {
			if (isValueChanged) {
				setIsValueChanged(false);
			}

			const record = updateModal.record;
			const mode = record?.deletion_mode;
			const cycle = record?.cycle;
			form.setFieldsValue({
				deletion_mode: mode == 'all' ? 'all' : 'masked',
				cycle: cycle == 'monthly' ? 'monthly' : 'quarterly',
				starting_date: moment(record?.starting_date, dateFormat),
				trigger_day: record.trigger_day?.weekday ? 'week' : 'day',
			});
			if (record.trigger_day?.weekday) {
				setWeek(record.trigger_day?.weekday);
				setWeekOption(record.trigger_day?.day == 5 ? 'last' : 'first');
			} else {
				setDay(record.trigger_day?.day);
			}
			setPreserveDay(record?.data_retention);
		}
	}, [updateModal]);

	return (
		<>
			<CustomModal
				isOpen={updateModal.state}
				className='successModal updatePolicyModal'
				onClose={() => setUpdateModal({ state: false, record: '' })}
				footer={[]}
				width={420}
			>
				<div className='updatePolicyWrapper'>
					<h3>Edit delete policy</h3>
					<Form
						layout='vertical'
						onFinish={onSubmit}
						form={form}
						requiredMark={false}
						className='deleteDataForm'
						initialValues={{
							deletion_mode: 'all',
							delete_data_repeat: 'monthly',
							trigger_day: 'day',
						}}
						onValuesChange={() => setIsValueChanged(true)}
					>
						<Form.Item
							name='deletion_mode'
							label={<>Select Bucket</>}
							tooltip={{
								title: 'Select whether you want to delete all data or only masked data.',
								icon: <InfoCircleFilled />,
							}}
						>
							<Select id='bucket_name' disabled={true}>
								<Option value='all'>
									All data <small>(from fiq-screenshots, forwarded and review buckets)</small>
								</Option>
								<Option value='masked'>
									Masked Data <small>(from forwarded and review buckets)</small>
								</Option>
							</Select>
						</Form.Item>
						<div className='inlineFields'>
							<Form.Item
								name='cycle'
								label={<>Repeat</>}
								tooltip={{
									title: 'Set up when the deletion of the data will repeat.',
									icon: <InfoCircleFilled />,
								}}
							>
								<Select id='cycle'>
									<Option value='monthly'>Monthly</Option>
									<Option value='quarterly'>Quarterly</Option>
								</Select>
							</Form.Item>
							<Form.Item name={'starting_date'} label='Start Date'>
								<DatePicker format={dateFormat} />
							</Form.Item>
						</div>
						<Form.Item
							label={<>Delete data on</>}
							name='trigger_day'
							tooltip={{
								title: 'Set up when the deletion of the data will repeat.',
								icon: <InfoCircleFilled />,
							}}
						>
							<Radio.Group className='checkbox_wrapper'>
								<div className='optionWrapper'>
									<Radio value={'day'}></Radio>
									<div className='dayOption'>
										<Select value={day} onChange={handleDayChange}>
											{days.map((num) => {
												return (
													<Option key={num} value={num}>
														{num}
													</Option>
												);
											})}
										</Select>
										<p>day</p>
									</div>
								</div>
								<div className='optionWrapper'>
									<Radio value={'week'}></Radio>
									<div className='weekOption'>
										<Select value={weekOption} onChange={handleWeekOptionChange}>
											<Option value={'first'}>First</Option>
											<Option value={'last'}>Last</Option>
										</Select>
										<Select value={week} onChange={handleWeekChange}>
											<Option value={'monday'}>Monday</Option>
											<Option value={'tuesday'}>Tuesday</Option>
											<Option value={'wednesday'}>Wednesday</Option>
											<Option value={'thursday'}>Thursday</Option>
											<Option value={'friday'}>Friday</Option>
											<Option value={'saturday'}>Saturday</Option>
											<Option value={'sunday'}>Sunday</Option>
										</Select>
									</div>
								</div>
							</Radio.Group>
						</Form.Item>
						<Form.Item>
							<div className='preserveWrapper'>
								<p>Exclude data from last </p>
								<Select value={preserveDay} onChange={handlePreserveChange}>
									{days.map((num) => {
										return (
											<Option key={num} value={num}>
												{num}
											</Option>
										);
									})}
								</Select>
								<p>days from deletion </p>
							</div>
						</Form.Item>
						<div className='updatePolicyButtons'>
							<CustomButton
								onClick={() => setUpdateModal({ state: false, record: '' })}
								key='btnClose'
								id='btnClose'
							>
								Cancel
							</CustomButton>

							<CustomButton
								className='r-commit'
								htmlType='submit'
								onClick={() => {
									setUpdateModal({ state: false, record: '' });
								}}
								type='primary'
								key='btndownload'
								isDisabled={isValueChanged ? false : true}
							>
								Save
							</CustomButton>
						</div>
					</Form>
				</div>
			</CustomModal>
		</>
	);
};

export default UpdatePolicy;
