import { PlusOutlined } from '@ant-design/icons';
import { Tooltip, notification } from 'antd';
import axios from 'axios';
import { useEffect, useState } from 'react';
import ROOT from '../../client';
import { READ_ONLY_ROLE } from '../../utilities/constants';
import Loader from '../Loader';
import NewUserForm from './newUserForm';
import './style.scss';
import AllUsers from './users';

const CreateUsers = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [tableData, setTableData] = useState([]);
	const [newUserForm, setNewUserForm] = useState(false);
	const [editUser, setEditUser] = useState();

	const createRole = async () => {
		try {
			await axios.post(`${ROOT}/api/create-role`, { role: READ_ONLY_ROLE });
		} catch (error) {
			console.error(error);
		}
	};

	const getUsers = async (action) => {
		try {
			if (!isLoading) {
				setIsLoading(true);
			}
			const res = await axios.get(`${ROOT}/api/get-users`);
			if (res?.data) {
				const data = res.data;
				let users = [];
				for (let key in data) {
					if (
						key != 'kibanaro' &&
						key != 'kibanaserver' &&
						key != 'readall' &&
						key != 'snapshotrestore' &&
						key != 'logstash'
					) {
						let role = '';
						let backendRoles = data[key].backend_roles;
						if (backendRoles.length) {
							role = backendRoles[0];
						}
						const buckets = data[key]?.attributes?.buckets
							? JSON.parse(data[key].attributes.buckets)
							: [];

						users.push({
							...data[key],
							username: key,
							role,
							key: key,
							buckets: buckets.length ? buckets.join(', ') : 'All Buckets',
							scope: data[key]?.attributes?.scope || 'Global Scope',
							updatedAt: data[key]?.attributes?.updatedAt || '',
							createdAt: data[key]?.attributes?.createdAt || '',
						});
					}
				}
				let sortedUsers = [];
				if (users.length) {
					sortedUsers = users.sort((a, b) => {
						if (!a.updatedAt) return 1;
						if (!b.updatedAt) return -1;
						return new Date(b.updatedAt) - new Date(a.updatedAt);
					});
				}
				setTableData(sortedUsers);
				setIsLoading(false);
				if (newUserForm) {
					setNewUserForm(false);
				}
				if (action) {
					notification.success({
						message:
							action == 'delete'
								? 'Deleted Successfully.'
								: action == 'update'
								? 'Updated Successfully.'
								: 'Created Successfully.',
					});
				}
			}
		} catch (error) {
			setNewUserForm(false);
			setIsLoading(false);
			console.error(error);
		}
	};

	useEffect(() => {
		createRole();
		getUsers();
	}, []);

	useEffect(() => {
		if (editUser) {
			setNewUserForm(true);
		}
	}, [editUser]);

	const handleAddUser = () => {
		setNewUserForm(true);
	};

	return (
		<div className='createUserPage'>
			{isLoading && <Loader />}
			{newUserForm && (
				<NewUserForm
					tableData={tableData}
					getUsers={getUsers}
					setIsLoading={setIsLoading}
					setNewUserForm={setNewUserForm}
					editUser={editUser}
					setEditUser={setEditUser}
				/>
			)}
			{!newUserForm && (
				<div className='policyStatusWrapper'>
					<div className='createUser'>
						<Tooltip title='New User'>
							<PlusOutlined onClick={handleAddUser} />
						</Tooltip>
					</div>
					<AllUsers
						setIsLoading={setIsLoading}
						tableData={tableData}
						getUsers={getUsers}
						setEditUser={setEditUser}
					/>
				</div>
			)}
		</div>
	);
};

export default CreateUsers;
