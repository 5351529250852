import { Axis, BarSeries, Chart, ScaleType, Settings } from '@elastic/charts';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import './graph.scss';

const Graph = ({ graphVisible, setGraphEvent, graphdata }) => {
	const elementClickHandler = (data) => {
		try {
			const date = moment(data[0][0].x).toISOString();
			let event = {};
			event.start = date;
			event.end = moment(date).add(23, 'hours').toISOString();
			event.chart = true;
			setGraphEvent(event);
		} catch (error) {
			console.error(error);
		}
	};
	const formatXAxis = (tickItem) => {
		try {
			return moment(tickItem).utc().format('HH:mm DD MMM');
		} catch (error) {
			console.error(error);
		}
	};
	return (
		<>
			{graphdata ? (
				<div className={graphVisible ? 'viewGraph' : 'collapseGraph'}>
					<Chart size={{ height: 200 }}>
						<Settings
							showLegend={false}
							onElementClick={elementClickHandler}
							className='settings'
						/>
						<BarSeries
							id='image_count'
							name='image_count'
							data={graphdata}
							xScaleType={ScaleType.Time}
							xAccessor={0}
							yAccessors={[1]}
							enableHistogramMode={true}
						/>
						<Axis id='bottom-axis' position='bottom' tickFormat={formatXAxis} />
						<Axis id='left-axis' position='left' showGridLines />
					</Chart>
				</div>
			) : null}
		</>
	);
};

Graph.propTypes = {
	graphVisible: PropTypes.bool,
	setGraphEvent: PropTypes.func,
	graphdata: PropTypes.array,
};
export default Graph;
