import {
	CheckCircleFilled,
	CheckOutlined,
	CloseCircleFilled,
	CloseOutlined,
	InfoCircleFilled,
} from '@ant-design/icons';
import { Checkbox, Col, DatePicker, Form, Input, Row, Select, Tooltip } from 'antd';
import Text from 'antd/lib/typography/Text';
import axios from 'axios';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ROOT from '../../client';
import { saveReRedactionStatus } from '../../store/actions';
import CustomButton from '../Button';
import CustomModal from '../Modal';
import customTost from '../Notification';
import './style.scss';

const { Option } = Select;

const Forwarding = ({
	isProxySelected,
	initialValues,
	pegEnvars,
	setPegEnvars,
	setProxySelected,
	setDisableRedactionTab,
}) => {
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [pegDeploySuccess, setPegDeploySuccess] = useState({ modelState: false, deploystatus: {} });
	const initalMasking =
		form.getFieldValue('FORWARD_EXISTING_MASKED_RECORDS') === 'true' ? true : false;
	const initalStartDate = form.getFieldValue('UTC_START_DATE_FOR_FORWARDING');
	const initalEndDate = form.getFieldValue('UTC_END_DATE_FOR_FORWARDING');
	const [fwdMasking, setFwdMasking] = useState(initalMasking);
	const [reRedaction, setReRedaction] = useState(true);
	const [isdeploying, setIsDeploying] = useState(false);
	const [redactionLoading, SetRedactionLoading] = useState(false);
	const [disableDeploy, setDisableDeploy] = useState(true);
	const [disableForward, setDisableForward] = useState(false);
	const [updatedValues, setUpdatedValues] = useState([]);
	const [isUrlValid, setIsUrlValid] = useState({ state: true, isEmpty: false });
	const [isValidPort, setIsValidPort] = useState(true);
	// const [status, setStatus] = useState('')

	useEffect(() => {
		try {
			form.setFieldsValue(initialValues);
			if (initialValues.forward_to_fluentd === 'false') {
				setReRedaction(false);
				setDisableForward(true);
				form.setFieldsValue({
					FORWARD_LIVE_RECORDS: 'false',
					FORWARD_EXISTING_MASKED_RECORDS: 'false',
				});
			} else {
				setReRedaction(true);
				let fwdValue = false;
				if (initialValues.FORWARD_EXISTING_MASKED_RECORDS === 'true') {
					fwdValue = true;
				}
				setFwdMasking(fwdValue);
			}
			setDisableDeploy(initialValues.forward_to_fluentd !== undefined ? false : true);
		} catch (err) {
			console.error(err);
		}
	}, [initialValues]);

	const checkKeyDown = (e) => {
		if (e.code === 'Enter') e.preventDefault();
	};

	const removeSlash = (url) => {
		return url.replace(/\/+$/, '');
	};

	const afterUpdateEnvars = ({ flag, deploystatus }) => {
		// setStatus('')
		setDisableRedactionTab(false);
		flag ? setIsDeploying(false) : SetRedactionLoading(false);
		setPegDeploySuccess({ modelState: true, deploystatus });
	};

	// Forwarding Tab - Deploy button click event
	const onSubmit = (values) => {
		try {
			setDisableRedactionTab(true);
			setDisableForward(true);
			const forwardPlatformVal = !isModalOpen ? true : false;
			if (forwardPlatformVal) {
				setIsDeploying(true);
				setReRedaction(true);
			} else {
				setDisableDeploy(true);
				dispatch(saveReRedactionStatus(true));
				SetRedactionLoading(true);
			}
			if (values?.UTC_START_DATE_FOR_FORWARDING) {
				const UTC_START_DATE = moment(values.UTC_START_DATE_FOR_FORWARDING).format(
					'YYYY-MM-DDTHH:mm:ss',
				);
				const UTC_END_DATE = moment(values.UTC_END_DATE_FOR_FORWARDING).format(
					'YYYY-MM-DDTHH:mm:ss',
				);
				values.UTC_START_DATE_FOR_FORWARDING = UTC_START_DATE;
				values.UTC_END_DATE_FOR_FORWARDING = UTC_END_DATE;
			}
			if (values?.symspell__edit_distance) {
				values.symspell__edit_distance = values.symspell__edit_distance === 'true' ? '1' : '0';
			}

			values.has_fluentd_proxy = isProxySelected ? 'true' : 'false';
			// clear values if use-proxy is not enable
			if (!isProxySelected) {
				values.fluentd_username = values.fluentd_username = '';
				values.fluentd_password = values.fluentd_password = '';
				values.https_proxy = values.https_proxy = '';
			}
			values.external_fluentd_port = values?.external_fluentd_port
				? values.external_fluentd_port.trim()
				: '';
			// remove last slash from the url
			if (values.external_fluentd_url) {
				let str = values.external_fluentd_url.trim();
				const lastChar = str.substring(str.length - 1, str.length);
				if (lastChar === '/') {
					str = removeSlash(str);
				}

				values.external_fluentd_url = str;
			}

			const updatedVars = pegEnvars.map((obj) => {
				if (obj.name in values === true) {
					const newVal = {
						name: obj.name,
						value: values[obj.name],
					};
					return newVal;
				} else {
					return obj;
				}
			});
			setPegEnvars(updatedVars);
			setUpdatedValues(updatedVars);

			if (forwardPlatformVal) {
				updateConfig(updatedVars, forwardPlatformVal);
			}
		} catch (err) {
			setDisableRedactionTab(false);
			setDisableForward(false);
			console.error(err);
		}
	};

	const updateConfig = (updatedVars, flag) => {
		try {
			axios
				.post(`${ROOT}/api/update-deployment-envars`, { updatedVars, flag })
				.then(async (res) => {
					if (res?.data?.statusCode === 200) {
						if (flag) {
							setDisableForward(form.getFieldValue('forward_to_fluentd') === 'true' ? false : true);
							setReRedaction(form.getFieldValue('forward_to_fluentd') === 'true' ? true : false);
							afterUpdateEnvars({
								flag: flag,
								deploystatus: {},
							});
						} else {
							const res = await axios.post(`${ROOT}/api/stop-create-scopecron`);
							if (res) updateElasticRecords(flag);
						}
					}
				})
				.catch((err) => {
					createCronJob(flag);
					setDisableForward(false);
					setDisableRedactionTab(false);
					flag ? setIsDeploying(false) : SetRedactionLoading(false);
					customTost({
						type: 'error',
						message: err?.response?.data?.message
							? err.response.data.message
							: 'Internal server error.',
					});
				});
		} catch (err) {
			setDisableForward(false);
			setDisableRedactionTab(false);
			flag ? setIsDeploying(false) : SetRedactionLoading(false);
			console.error(err);
		}
	};

	const updateElasticRecords = (flag) => {
		try {
			const query = {
				query: {
					bool: {
						must: [
							{
								match: {
									is_masked: true,
								},
							},
							{
								exists: {
									field: 'forward_status',
								},
							},
						],
						must_not: [
							{
								match: {
									forward_status: 'completed',
								},
							},
						],
					},
				},
				script: {
					source:
						"ctx._source.scope_regexes=null;ctx._source.scope_word_list=null;ctx._source.scoping_done=false;ctx._source.is_masked=false;ctx._source.masking_attempts_left=2;ctx._source.masked_at=null;ctx._source.forward_status='pending';ctx._source.forward_attempts_left=5;ctx._source.forwarded_at=null;ctx._source.last_point_of_failure=null;ctx._source.reason_for_failure=null;ctx._source.trigger_manual_retry=false;for (def log : ctx._source.raw_logs) { log.forward_status = 'pending';log.forwarded_at = null }",
					lang: 'painless',
				},
			};

			/* istanbul ignore next */
			setTimeout(() => {
				axios
					.post(`${ROOT}/api/update-elastic-records`, {
						query,
						index: 'event_logs',
					})
					.then((res) => {
						const vc = res?.data?.elRes?.meta?.body?.version_conflicts;
						if (vc > 0) {
							updateElasticRecords(flag);
						} else {
							createCronJob(flag);
						}
					})
					.catch((err) => {
						createCronJob(flag);
						console.error(err);
						afterUpdateEnvars({
							flag: flag,
							deploystatus: { error: true },
						});
					});
			}, 10000);
		} catch (err) {
			createCronJob(flag);
			console.error(err);
		}
	};

	const createCronJob = (flag) => {
		try {
			axios
				.get(`${ROOT}/api/create-cronjob`)
				.then(() => {
					setDisableDeploy(false);
					dispatch(saveReRedactionStatus(false));
					afterUpdateEnvars({
						flag: flag,
						deploystatus: { success: true },
					});
				})
				.catch((err) => {
					setDisableDeploy(false);
					dispatch(saveReRedactionStatus(false));
					console.error(err);
				});
		} catch (err) {
			console.error(err);
		}
	};

	const handleClose = () => {
		setPegDeploySuccess({ modelState: false, deploystatus: {} });
	};

	const deployBtnState = (validUrl, validPort) => {
		try {
			const forwardPlatformVal = form.getFieldValue('forward_to_fluentd');
			const externalUrl = form.getFieldValue('external_fluentd_url');
			const forwardLiveVal = form.getFieldValue('FORWARD_LIVE_RECORDS');
			const forwardExistingVal = form.getFieldValue('FORWARD_EXISTING_MASKED_RECORDS');
			if (forwardPlatformVal === 'true') {
				if (
					externalUrl &&
					validUrl &&
					validPort &&
					(forwardLiveVal === 'true' || forwardExistingVal === 'true')
				) {
					return false;
				}
				return true;
			} else {
				return validUrl && validPort ? false : true;
			}
		} catch (err) {
			console.error(err);
		}
	};

	const handleFluentdUrl = (e) => {
		try {
			const forwardPlatformVal = form.getFieldValue('forward_to_fluentd');
			const value = e?.target?.value ? e.target.value : '';
			if (value) {
				let validRegex = isValidURL(value);
				if (validRegex) {
					setIsUrlValid({ state: true, isEmpty: false });
					setDisableDeploy(deployBtnState(true, isValidPort));
				} else {
					setDisableDeploy(true);
					setIsUrlValid({ state: false, isEmpty: false });
				}
			} else {
				setDisableDeploy(deployBtnState(true, isValidPort));
				setIsUrlValid({ state: true, isEmpty: forwardPlatformVal === 'true' ? true : false });
			}
		} catch (err) {
			console.error(err);
		}
	};

	const isValidURL = (string) => {
		try {
			const res = string.match(
				// eslint-disable-next-line
				/(https:\/\/.)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
			);
			return res !== null;
		} catch (err) {
			console.error(err);
		}
	};

	const handlePort = (e) => {
		try {
			const value = e?.target?.value ? e.target.value : '';
			if (value) {
				setDisableDeploy(true);
				setIsValidPort(false);
				if (value.trim()) {
					if (!isNaN(value)) {
						setDisableDeploy(deployBtnState(isUrlValid?.state, true));
						setIsValidPort(true);
					} else {
						setDisableDeploy(deployBtnState(isUrlValid?.state, false));
					}
				}
			} else {
				setDisableDeploy(deployBtnState(isUrlValid?.state, true));
				setIsValidPort(true);
			}
		} catch (err) {
			console.error(err);
		}
	};

	// Disable UTC START/End Date based on UTC Date
	const disabledDate = (current, type) => {
		try {
			const date = form.getFieldValue(type);
			return current && current.valueOf() <= Date.parse(date);
		} catch (err) {
			console.error(err);
		}
	};

	// Update UTC Start/End Date
	const updateUTCDate = (val) => {
		try {
			form.setFieldsValue({
				UTC_START_DATE_FOR_FORWARDING: initalStartDate,
				UTC_END_DATE_FOR_FORWARDING: initalEndDate,
			});
			let masked = val === 'true' ? true : false;
			setDisableDeploy(deployBtnState(isUrlValid?.state, isValidPort));
			setFwdMasking(masked);
		} catch (err) {
			console.error(err);
		}
	};

	const handleLiveForward = () => {
		try {
			setDisableDeploy(deployBtnState(isUrlValid?.state, isValidPort));
		} catch (err) {
			console.error(err);
		}
	};

	const handleForwardPlatform = (e) => {
		try {
			const externalUrl = form.getFieldValue('external_fluentd_url');
			if (e === 'true') {
				setDisableForward(false);
				setFwdMasking(false);
				setReRedaction(true);
				if (!externalUrl) {
					setIsUrlValid({ state: true, isEmpty: true });
				}
			} else {
				form.setFieldsValue({
					FORWARD_LIVE_RECORDS: 'false',
					FORWARD_EXISTING_MASKED_RECORDS: 'false',
				});
				setDisableForward(true);
				setFwdMasking(false);
				setReRedaction(false);
				if (!externalUrl) {
					setIsUrlValid({ state: true, isEmpty: false });
				}
			}
			setDisableDeploy(deployBtnState(isUrlValid?.state, isValidPort));
		} catch (err) {
			console.error(err);
		}
	};

	const handleReRedaction = async () => {
		try {
			setIsModalOpen(false);
			updateConfig(updatedValues, false);
		} catch (err) {
			console.error(err);
		}
	};

	// handle proxy fields errors
	const handleProxyCheckbox = () => {
		// check the field error
		const hasUrl = form.getFieldError('https_proxy');

		// if is-proxy-selected than reset the error field
		if (isProxySelected) {
			if (hasUrl?.length) form.resetFields(['https_proxy']);
		}

		setProxySelected(!isProxySelected);
	};

	const handleRedactionCancel = () => {
		setDisableDeploy(false);
		setIsModalOpen(false);
		SetRedactionLoading(false);
		setDisableRedactionTab(false);
		dispatch(saveReRedactionStatus(false));
	};

	return (
		<>
			<CustomModal
				isOpen={isModalOpen}
				closable={false}
				centered
				onClose={() => setIsModalOpen(false)}
				footer={[
					<CustomButton
						className={'re-yes'}
						key='confirmBtn'
						type='primary'
						icon={<CheckOutlined />}
						onClick={() => handleReRedaction()}
					>
						{'Yes'}
					</CustomButton>,
					<CustomButton
						className={'re-cancel'}
						key='closeBtn'
						style={{ margin: '0 20px' }}
						icon={<CloseOutlined />}
						onClick={() => handleRedactionCancel()}
					>
						{'Cancel'}
					</CustomButton>,
				]}
			>
				<div style={{ textAlign: 'center' }}>
					<p style={{ marginBottom: '10px' }}>
						Please note this will stop the current redaction jobs in queue and will rerun the
						masking job on all images that have not been forwarded. During this time updates to
						redaction list will be disabled and the job may take significant time to run.<br></br>
					</p>
					<p>Are you sure you want to run this job?</p>
				</div>
			</CustomModal>
			<Form
				layout='vertical'
				onFinish={onSubmit}
				form={form}
				requiredMark={false}
				onKeyDown={(e) => checkKeyDown(e)}
				className='pegConfigForm'
			>
				<div className='general_wrapper'>
					<Row className='row_settings' gutter={[16, 12]}>
						<Col span={8}>
							<Form.Item
								name='forward_to_fluentd'
								label={<>Forward to platform</>}
								tooltip={{
									title:
										'Set "true" to forward logs to the platform. When set to "False",\
                   it acts as a master primary switch and no forwarding of logs will occur.',
									icon: <InfoCircleFilled />,
								}}
							>
								<Select
									id='forward_to_fluentd'
									onChange={handleForwardPlatform}
									disabled={redactionLoading || isdeploying}
								>
									<Option value='true'>true</Option>
									<Option value='false'>false</Option>
								</Select>
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item
								label={<>External platform URL</>}
								name='external_fluentd_url'
								tooltip={{
									title: 'The URL to the platform (for ex: "https://platform.testing-fiq.com")',
									icon: <InfoCircleFilled />,
								}}
							>
								<Input
									id='external_fluentd_url'
									className={!isUrlValid?.state || isUrlValid?.isEmpty ? 'invalidField' : ''}
									onChange={handleFluentdUrl}
									disabled={redactionLoading || isdeploying}
								/>
							</Form.Item>
							{!isUrlValid?.state && <p className='fieldError'>Please enter a valid URL.</p>}
							{isUrlValid?.isEmpty && <p className='fieldError'>Required field.</p>}
						</Col>
						<Col span={8}>
							<Form.Item
								label={<>Port for forwarding</>}
								name='external_fluentd_port'
								tooltip={{
									title: 'Port to forward logs to the platform (Default Port: 443)',
									icon: <InfoCircleFilled />,
								}}
							>
								<Input
									id='external_fluentd_port'
									className={!isValidPort ? 'invalidField' : ''}
									onChange={handlePort}
									disabled={redactionLoading || isdeploying}
								/>
							</Form.Item>
							{!isValidPort && <p className='fieldError'>Please enter only numeric value.</p>}
						</Col>
					</Row>

					<Row className='row_settings' gutter={[16, 12]}>
						<Col span={6}>
							<Form.Item
								label={<>Forward live records</>}
								name='FORWARD_LIVE_RECORDS'
								tooltip={{
									title:
										'Set ‘true’ for forwarding live records to the platform else no forwarding \
                   will happen to the platform',
									icon: <InfoCircleFilled />,
								}}
							>
								<Select
									id='FORWARD_LIVE_RECORDS'
									disabled={disableForward}
									onChange={handleLiveForward}
								>
									<Option value='true'>true</Option>
									<Option value='false'>false</Option>
								</Select>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								label={<>Forward existing masked records</>}
								name='FORWARD_EXISTING_MASKED_RECORDS'
								tooltip={{
									title:
										'Set true will forward logs ranging from the below field \
                  (Start date for forwarding & End date for forwarding)',
									icon: <InfoCircleFilled />,
								}}
							>
								<Select
									id='FORWARD_EXISTING_MASKED_RECORDS'
									onChange={updateUTCDate}
									disabled={disableForward}
								>
									<Option value='true'>true</Option>
									<Option value='false'>false</Option>
								</Select>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								label={<>UTC start date for forwarding</>}
								name='UTC_START_DATE_FOR_FORWARDING'
								tooltip={{
									title: 'Start date to search forward logs',
									icon: <InfoCircleFilled />,
								}}
								dependencies={['UTC_END_DATE_FOR_FORWARDING']}
								rules={[
									{
										validator(_, value) {
											if (value) {
												return Promise.resolve();
											} else {
												return Promise.reject(new Error('Required field.'));
											}
										},
									},
									({ getFieldValue }) => ({
										validator(_, value) {
											const endDate = getFieldValue('UTC_END_DATE_FOR_FORWARDING');
											if (value.valueOf() >= Date.parse(endDate)) {
												return Promise.reject(
													new Error('Start date can not be equal or greater than End date.'),
												);
											} else {
												return Promise.resolve();
											}
										},
									}),
								]}
							>
								<DatePicker
									disabled={!fwdMasking}
									placeholder='Select Date & Time'
									size='large'
									className='date_margin'
									format='YYYY-MM-DD HH:mm:ss'
									showTime={{ format: 'HH:mm:ss' }}
								/>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								label={<>UTC end date for forwarding</>}
								name='UTC_END_DATE_FOR_FORWARDING'
								tooltip={{
									title: 'End date to search forward logs',
									icon: <InfoCircleFilled />,
								}}
								rules={[
									{
										validator(_, value) {
											if (value) {
												return Promise.resolve();
											} else {
												return Promise.reject(new Error('Required field.'));
											}
										},
									},
								]}
							>
								<DatePicker
									disabled={!fwdMasking}
									disabledDate={(c) => disabledDate(c, 'UTC_START_DATE_FOR_FORWARDING')}
									placeholder='Select Date & Time'
									size='large'
									className='date_margin'
									format='YYYY-MM-DD HH:mm:ss'
									showTime={{ format: 'HH:mm:ss' }}
								/>
							</Form.Item>
						</Col>
					</Row>

					<div className='proxy-checkbox-wrapper'>
						<Checkbox
							name='has_fluentd_proxy'
							checked={isProxySelected}
							onChange={handleProxyCheckbox}
						>
							{'Use Proxy'}
						</Checkbox>
						<Tooltip
							className='proxy-tooltip'
							title={
								'Need the proxy to access the platform from this host. set ‘true’\
                 to provide an https proxy for the platform URL else a proxy is not required.'
							}
						>
							<InfoCircleFilled />
						</Tooltip>
					</div>

					<Row>
						<Col span={6}>
							<Form.Item
								label={<>https proxy</>}
								name='https_proxy'
								tooltip={{
									title:
										'A proxy for the platform (for ex: "http://proxy.example.com:8080" \
                    Note: Only required if has platform proxy is set to “true”)',
									icon: <InfoCircleFilled />,
								}}
								rules={[
									{ required: isProxySelected, message: 'Required' },
									{ type: isProxySelected ? 'url' : '', message: 'Invalid URL' },
								]}
							>
								<Input id='https_proxy' placeholder='Enter URL' disabled={!isProxySelected} />
							</Form.Item>
						</Col>
					</Row>
					<Row className='pegConfigButton'>
						<Col span={24}>
							<CustomButton
								className='deployBtn'
								type='primary'
								htmlType='submit'
								isLoading={isdeploying}
								isDisabled={disableDeploy}
							>
								{'DEPLOY'}
							</CustomButton>

							<Row>
								<Col span={24}>
									<p>
										<strong>Note: </strong>Re-redaction sync job can only be initiated if there is
										no active forwarding (<strong>&apos;Forward to platform&apos;</strong> should be
										<strong>&quot;false&quot;</strong>)
									</p>
									<CustomButton
										className={'re-redacte-btn'}
										type='primary'
										htmlType='submit'
										isLoading={redactionLoading}
										isDisabled={reRedaction}
										onClick={() => setIsModalOpen(true)}
									>
										{'RUN RE-REDACTION SYNC JOB'}
									</CustomButton>
								</Col>
							</Row>
						</Col>
					</Row>
				</div>
			</Form>

			<CustomModal
				isOpen={pegDeploySuccess.modelState}
				className='successModal modal_wrapper'
				onClose={handleClose}
				footer={[
					<CustomButton onClick={handleClose} type='primary' key='btnClose' id='btnClose'>
						{'CLOSE'}
					</CustomButton>,
				]}
			>
				<div className='success_modal'>
					{pegDeploySuccess?.deploystatus?.success ? (
						<>
							<CheckCircleFilled style={{ color: '#03A803', fontSize: '50px' }} />
							<div className='success_modal_header successHeader'>
								Deployed and updated successfully
							</div>
						</>
					) : (
						<>
							{pegDeploySuccess?.deploystatus?.error ? (
								<>
									<CloseCircleFilled style={{ color: 'red', fontSize: '50px' }} />
									<div className='success_modal_header'>Something went wrong.</div>
									<Text>Please run re-redaction job again</Text>
								</>
							) : (
								<>
									<CheckCircleFilled style={{ color: '#03A803', fontSize: '50px' }} />
									<div className='success_modal_header'>Deployed Successfully</div>
								</>
							)}
						</>
					)}
				</div>
			</CustomModal>
		</>
	);
};
Forwarding.propTypes = {
	form: PropTypes.object,
	setDisableDeploy: PropTypes.func,
	isProxySelected: PropTypes.bool,
	setUseProxy: PropTypes.func,
	isdeploying: PropTypes.bool,
	redactionLoading: PropTypes.bool,
	SetRedactionLoading: PropTypes.func,
	updateConfig: PropTypes.func,
	status: PropTypes.string,
	setStatus: PropTypes.func,
	initialValues: PropTypes.object,
	pegEnvars: PropTypes.array,
	setPegEnvars: PropTypes.func,
	setProxySelected: PropTypes.func,
	setDisableRedactionTab: PropTypes.func,
};

export default Forwarding;
