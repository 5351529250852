import PropTypes from 'prop-types';

import './style.scss';

export const Spinner = (p) => {
	return (
		<div className='spinner-wrapper'>
			<span className={`loader-sp ${p.className}`} />
		</div>
	);
};

Spinner.propTypes = {
	className: PropTypes.string,
};

const Loader = () => {
	return (
		<div className='loader-wrapper'>
			<div className='loader-contents'>
				<span className='loader-og' />
			</div>
		</div>
	);
};

export default Loader;
