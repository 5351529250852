import { ImportOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import CustomButton from '../Button';
import './importQuery.scss';

const ImportQuery = (props) => {
	const { setImportFile, setUpdateSignatureFlag, setAddFilter, setEditFilter } = props;
	const handleImport = () => {
		setImportFile(true);
		setUpdateSignatureFlag(false);
		setAddFilter(false);
		setEditFilter(false);
	};
	return (
		<CustomButton id='importQueryButton' icon={<ImportOutlined />} onClick={handleImport}>
			{'Import Query'}
		</CustomButton>
	);
};

ImportQuery.propTypes = {
	setImportFile: PropTypes.func,
	setUpdateSignatureFlag: PropTypes.func,
	setAddFilter: PropTypes.func,
	setEditFilter: PropTypes.func,
};

export default ImportQuery;
