import {
	ADD_INDEX_VALUE,
	BULK_DELETE_PROPS,
	LOGOUT_ACTION,
	NO_RECORDS_AVAILABLE,
	REDACTIONVIEW,
	REVIEWMODE,
	RE_REDACTION_STATUS,
	SAVE_ADMIN_USER_FLAG,
	SAVE_AREA_QUERY,
	SAVE_BUCKETS,
	SAVE_CONFIGURE_FIELD_STATUS,
	SAVE_DATE,
	SAVE_ENVARS,
	SAVE_FILE_CONFIGURATIONS,
	SAVE_FILTERS,
	SAVE_FILTER_FLAG,
	SAVE_GCP_FILENAME,
	SAVE_GRAPH_VISIBILITY,
	SAVE_IMAGE_DETAIL,
	SAVE_IMAGE_KEYS,
	SAVE_IMAGE_LOAD_COUNT,
	SAVE_IMG_URL,
	SAVE_MAPPING_KEY,
	SAVE_MAPPING_PROPS,
	SAVE_MASKED_BUCKET,
	SAVE_MASKED_FLAG,
	SAVE_NAME,
	SAVE_QUERY_STACK,
	SAVE_ROUTE_LOCATION,
	SAVE_SCREEN_SIGNATURE,
	SAVE_SEARCH_DATA,
	SAVE_SELECTED_BUCKET,
	SAVE_SESSION_FLAG,
	SAVE_SLOTS,
	SAVE_TOKEN_FLAG,
	SET_DELETE_MODE,
	SET_DELETE_RECORD_COUNT,
	SET_VIEW,
	UPDATE_CONFIGURE_FIELDS,
	UPDATE_ENVARS,
	USERDETAILS,
} from '../types';

export const saveIndexValue = (value) => {
	return {
		type: ADD_INDEX_VALUE,
		payload: {
			indexValue: value.index,
			allIndexes: value.allIndexes,
			indexAlias: value.alias,
			aliasFlag: value.aliasFlag,
			aliasIndexesArr: value.aliasIndexesArr,
		},
	};
};

export const saveMappingKey = (value) => {
	return {
		type: SAVE_MAPPING_KEY,
		payload: {
			mappingImageKey: value,
		},
	};
};

export const saveSearchData = (data) => {
	return {
		type: SAVE_SEARCH_DATA,
		payload: {
			searchData: data,
		},
	};
};

export const saveImgUrl = (data) => {
	return {
		type: SAVE_IMG_URL,
		payload: {
			imagesUrls: data,
		},
	};
};

export const saveFilters = (data) => {
	return {
		type: SAVE_FILTERS,
		payload: {
			filters: data,
		},
	};
};

export const saveDate = (data) => {
	return {
		type: SAVE_DATE,
		payload: {
			observedAtDetail: data,
		},
	};
};

export const saveImageLoadCount = (data) => {
	return {
		type: SAVE_IMAGE_LOAD_COUNT,
		payload: {
			imageLoadCount: data,
		},
	};
};

export const saveScreenSignature = (data) => {
	return {
		type: SAVE_SCREEN_SIGNATURE,
		payload: {
			screenSignature: data,
		},
	};
};

export const saveAreaQuery = (data) => {
	return {
		type: SAVE_AREA_QUERY,
		payload: {
			areaQuery: data,
		},
	};
};

export const configureFieldStatus = (value) => {
	return {
		type: SAVE_CONFIGURE_FIELD_STATUS,
		payload: {
			isConfigureFieldEnable: value,
		},
	};
};

export const updateConfigureFields = (data) => {
	return {
		type: UPDATE_CONFIGURE_FIELDS,
		payload: {
			configuredFields: data,
		},
	};
};

export const updateEnvarsStatus = (value) => {
	return {
		type: UPDATE_ENVARS,
		payload: {
			updateEnvars: value,
		},
	};
};

export const saveGcpFileName = (value) => {
	return {
		type: SAVE_GCP_FILENAME,
		payload: {
			gcpFileName: value,
		},
	};
};

export const saveBuckets = (data) => {
	return {
		type: SAVE_BUCKETS,
		payload: {
			buckets: data,
		},
	};
};

export const saveSelectedBucket = (data) => {
	return {
		type: SAVE_SELECTED_BUCKET,
		payload: {
			selectedBucket: data,
		},
	};
};

export const saveEnvars = (data) => {
	return {
		type: SAVE_ENVARS,
		payload: {
			envars: data,
		},
	};
};

export const saveCarouselSlots = (data) => {
	return {
		type: SAVE_SLOTS,
		payload: {
			carouselSlots: data,
		},
	};
};

export const saveImageKeys = (data) => {
	return {
		type: SAVE_IMAGE_KEYS,
		payload: {
			imageKeys: data,
		},
	};
};

export const saveFileConfigurations = (data) => {
	return {
		type: SAVE_FILE_CONFIGURATIONS,
		payload: {
			fileConfigurations: data,
		},
	};
};

export const saveImageDetail = (data) => {
	return {
		type: SAVE_IMAGE_DETAIL,
		payload: {
			imageDetail: data,
		},
	};
};

export const saveMaskedBucket = (data) => {
	return {
		type: SAVE_MASKED_BUCKET,
		payload: {
			maskedBucket: data,
		},
	};
};

export const setView = (data) => {
	return {
		type: SET_VIEW,
		payload: data,
	};
};

export const saveMappingProps = (data) => {
	return {
		type: SAVE_MAPPING_PROPS,
		payload: {
			mappingProps: data,
		},
	};
};

export const saveQueryStack = (data) => {
	return {
		type: SAVE_QUERY_STACK,
		payload: {
			queryStack: data,
		},
	};
};

export const saveGraphVisibility = (data) => {
	return {
		type: SAVE_GRAPH_VISIBILITY,
		payload: {
			graphVisibility: data,
		},
	};
};

export const saveMaskedFlag = (data) => {
	return {
		type: SAVE_MASKED_FLAG,
		payload: {
			maskedFlag: data,
		},
	};
};

export const saveTokenFlag = (data) => {
	return {
		type: SAVE_TOKEN_FLAG,
		payload: {
			tokenFlag: data,
		},
	};
};

export const saveName = (data) => {
	return {
		type: SAVE_NAME,
		payload: {
			username: data,
		},
	};
};

export const saveRouteLocation = (data) => {
	return {
		type: SAVE_ROUTE_LOCATION,
		payload: {
			routeLocation: data,
		},
	};
};

export const saveSessionFlag = (data) => {
	return {
		type: SAVE_SESSION_FLAG,
		payload: {
			sessionFlag: data,
		},
	};
};

export const saveAdminUserFlag = (data) => {
	return {
		type: SAVE_ADMIN_USER_FLAG,
		payload: {
			adminUserFlag: data,
		},
	};
};

export const saveFilterFlag = ({ showFilterFlag }) => {
	return {
		type: SAVE_FILTER_FLAG,
		payload: {
			showFilterFlag,
		},
	};
};

export const setDeleteMode = ({ isDeleteModeOn }) => {
	return {
		type: SET_DELETE_MODE,
		payload: {
			isDeleteModeOn,
		},
	};
};

export const setDeleteRecordCount = ({ deleteRecordCount = 0 }) => {
	return {
		type: SET_DELETE_RECORD_COUNT,
		payload: {
			deleteRecordCount,
		},
	};
};

export const bulkDeleteProps = ({
	isDeleteInProgressFlag = false,
	deleteState = '',
	deleteUUID = '',
}) => {
	return {
		type: BULK_DELETE_PROPS,
		payload: {
			isDeleteInProgressFlag,
			deleteState,
			deleteUUID,
		},
	};
};

export const setIsNoRecordsAvailable = ({ noRecordsAvailable }) => {
	return {
		type: NO_RECORDS_AVAILABLE,
		payload: {
			noRecordsAvailable,
		},
	};
};

export const logOutAction = () => {
	return {
		type: LOGOUT_ACTION,
	};
};

export const saveReRedactionStatus = (data) => {
	return {
		type: RE_REDACTION_STATUS,
		payload: {
			reRedactionStatus: data,
		},
	};
};

export const saveRedactionView = (data) => {
	return {
		type: REDACTIONVIEW,
		payload: {
			redactionView: data,
		},
	};
};

export const saveReviewMode = (data) => {
	return {
		type: REVIEWMODE,
		payload: {
			reviewMode: data,
		},
	};
};

export const saveUserDetails = (data) => {
	return {
		type: USERDETAILS,
		payload: {
			userDetails: data,
		},
	};
};
