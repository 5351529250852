import PropTypes from 'prop-types';
import React from 'react';

import PageNotFound from './components/PageNotFound';

class ErrorBoundary extends React.Component {
	state = {
		errorMessage: '',
	};

	static getDerivedStateFromError(error) {
		return { errorMessage: error.toString() };
	}

	componentDidCatch(error, info) {
		this.logErrorToServices(error.toString(), info.componentStack);
	}

	// A fake logging service.
	logErrorToServices = console.log;

	render() {
		if (this.state.errorMessage) {
			return (
				<PageNotFound
					title={`Something went wrong and there was an error, ${this.state.errorMessage}`}
				/>
			);
		}
		return this.props.children;
	}
}

ErrorBoundary.propTypes = {
	children: PropTypes.node,
};

export default ErrorBoundary;
