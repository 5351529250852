import { Checkbox } from 'antd';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { configureFieldStatus, updateConfigureFields } from '../../store/actions';
import CustomButton from '../Button';
import './sourceInfo.scss';

const SourceInfo = (props) => {
	const dispatch = useDispatch();
	const { selectedOption, setSelectedOption } = props;
	const [sourceInfo, setSourceInfo] = useState([]);
	const { configuredFields } = useSelector((store) => store.storeProps);

	useEffect(() => {
		try {
			if (selectedOption) {
				setSourceInfo(selectedOption);
			}
		} catch (error) {
			console.error(error);
		}
	}, [selectedOption]);

	const onChange = (event) => {
		try {
			let options = [...selectedOption];
			if (options.length) {
				const newoptions = options.map((opt) => {
					if (opt.value === event.target.value) {
						if (event.target.checked) {
							dispatch(updateConfigureFields([...configuredFields, { label: opt.value }]));
						} else {
							dispatch(
								updateConfigureFields(configuredFields.filter((item) => item.label !== opt.value)),
							);
						}
						return { ...opt, isChecked: event.target.checked };
					} else {
						return { ...opt };
					}
				});
				const checkFieldStatus = newoptions.filter((val) => val.isChecked === true);
				if (checkFieldStatus?.length) {
					dispatch(configureFieldStatus(true));
				} else {
					dispatch(configureFieldStatus(false));
				}
				setSelectedOption(newoptions);
			}
		} catch (error) {
			console.error('Error [SourceInfo onChange]: ', error);
		}
	};

	const clearAllFields = () => {
		try {
			let options = [...selectedOption];
			if (options.length) {
				const newoptions = options.map((opt) => {
					return { ...opt, isChecked: false };
				});
				dispatch(updateConfigureFields([]));
				dispatch(configureFieldStatus(false));
				setSelectedOption(newoptions);
			}
		} catch (error) {
			console.error('Error [ClearAllFields]: ', error);
		}
	};

	return (
		<>
			<ul className='sourceInfoList'>
				{sourceInfo.length > 0 &&
					sourceInfo.map((option, index) => {
						return (
							<li key={option.id}>
								<Checkbox
									key={option.id}
									value={option.value}
									onChange={onChange}
									checked={option.isChecked}
								>
									{option.value}
								</Checkbox>
								{index === 0 && (
									<CustomButton className='clear-all' onClick={clearAllFields}>
										Clear All
									</CustomButton>
								)}
							</li>
						);
					})}
			</ul>
		</>
	);
};

SourceInfo.propTypes = { selectedOption: PropTypes.array, setSelectedOption: PropTypes.func };

export default SourceInfo;
