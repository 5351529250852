import { CheckCircleFilled } from '@ant-design/icons';
import { Tooltip, Upload } from 'antd';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import ROOT from '../../client';
import { createMC } from '../../services';
import CustomButton from '../Button';
import { Spinner } from '../Loader';
import CustomModal from '../Modal';
import customTost from '../Notification';
import * as helper from '../helper';
import './style.scss';

const UploadList = (props) => {
	const {
		source,
		isUploading,
		setIsuploading,
		setIsFileUploaded,
		getDictionaryFile,
		downloadFile,
	} = props;
	const { envars } = useSelector((store) => store.storeProps);
	const [uploadedFile, setUploadedFile] = useState();
	const [sourceCall, setSourceCall] = useState('Upload');
	const [showSuccessModal, setSuccessModal] = useState(false);

	const fileName = source === 'Pass' ? 'pass-list.txt' : 'block-list.txt';
	const minioClient = createMC(envars);

	// useEffect(() => {
	// 	getFile(fileName);
	// 	// eslint-disable-next-line
	// }, []);

	// Check if file is getting uploaded for Allow list or Block List
	const updateUploadState = (state) => {
		let newState;
		if (source === 'Pass') {
			newState = { Pass: state, Block: false };
		} else {
			newState = { Pass: false, Block: state };
		}

		setIsuploading(newState);
	};

	// Validate if session is active before uploading file to Minio
	const validateSessionAndUpload = async (streamFile, isListEmpty) => {
		try {
			const apiPath = `${ROOT}/api/get-all-indices`;
			axios.get(apiPath).then((resp) => {
				if (resp && resp?.data) {
					uploadToMinio(streamFile, isListEmpty);
				}
			});
		} catch (error) {
			customTost({
				type: 'error',
				message: 'Something went wrong.',
			});
		}
	};

	const uploadToMinio = (stream, isListEmpty) => {
		// Upload file to minio
		if (minioClient) {
			/* istanbul ignore next */
			minioClient.putObject('dictionaries', fileName, stream, function (err) {
				if (err) {
					updateUploadState(false);
					customTost({
						type: 'error',
						message: 'Something went wrong.',
					});
				} else {
					updateUploadState(false);
					// Update on UI, the latest file uploaded
					getFile(fileName);
					getDictionaryFile();
					// setLoading(false);
					setSuccessModal(true);
					setIsFileUploaded(true);
					if (!isListEmpty) {
						// setList([]);
						// setListText('');
						// setDuplicateItems([]);
					}
				}
			});
		}
	};

	// Fetch Allow/Block List file from minio
	/* istanbul ignore next */
	const getFile = () => {
		try {
			minioClient
				.statObject('dictionaries', fileName)
				.then((metaData) => {
					if (metaData) {
						minioClient
							.presignedGetObject('dictionaries', fileName, 604800, {
								'response-content-disposition': 'attachment',
							})
							.then((res) => {
								if (res) {
									setUploadedFile(res);
								}
							})
							.catch((error) => {
								console.error(error);
							});
					}
				})
				.catch((err) => {
					console.error(err);
					customTost({
						type: 'error',
						message:
							err.code === 'NotFound'
								? `${source} List File does not exist yet. Please upload a new file.`
								: 'Something went wrong. Please try after sometime.',
					});
				});
		} catch (err) {
			console.error(err);
		}
	};

	// Create a new file to be uploaded to minio
	const createFile = (wordsWithComma, isListEmpty = false) => {
		let newlineWords = wordsWithComma.replace(/","/g, '\n');
		newlineWords = newlineWords.replace(/(^"|"$)/g, '');
		validateSessionAndUpload(newlineWords, isListEmpty);
	};

	const validRequest = ({ onSuccess }) => {
		onSuccess(() => {
			console.log('valid');
		});
	};

	// Upload props
	const uploadProps = {
		name: 'file',
		accept: '.txt',
		maxCount: 1,
		customRequest: validRequest,
		beforeUpload(file) {
			try {
				setSourceCall('Upload');
				if (file.type !== 'text/plain') {
					customTost({
						type: 'error',
						message: 'Please upload text file only.',
					});
					return false;
				}

				let reader = new FileReader();
				let wordsList = [];
				let combinedArr = [];
				reader.onload = function (event) {
					// setTempFileName(file?.name);
					updateUploadState(true);
					wordsList = event?.target?.result?.split(/\r?\n/);
					wordsList = wordsList.map((n) => n?.trim()?.toLowerCase()?.split(' ')[0]);
					// Check for empty words
					wordsList = wordsList.filter((n) => n);
					let lastElement = wordsList[wordsList.length - 1];
					!lastElement && wordsList.splice(wordsList.length - 1, 1);
					wordsList = wordsList.map((i) => (i.endsWith(' 1') ? i : i + ' 1'));
					// Remove duplicates
					combinedArr = helper.removeDuplicates(wordsList);
					// Enclose element in double quotes
					combinedArr = combinedArr.map((ele) => `"${ele}"`);

					let commaSeparated = combinedArr.toString();
					createFile(commaSeparated, true);
				};

				reader.onerror = function () {
					updateUploadState(false);
				};

				reader.readAsText(file);
			} catch (error) {
				updateUploadState(false);
				console.error(error);
			}
		},
	};

	const downloadUpdatedList = () => {
		if (uploadedFile) {
			// if (source === 'Pass') {
			// 	downloadTriggerPass?.current?.click();
			// } else {
			// 	downloadTriggerBlock?.current?.click();
			// }
			downloadFile?.current?.click();
			setSuccessModal(false);
		}
	};

	return (
		<>
			<div className={`${source}_wrapper`}>
				<div className='upload_wrapper'>
					<Tooltip placement='top' title={`Upload New ${source} List Document`}>
						<Upload id={`uploadField_${source}`} {...uploadProps} className='upload_wrapper'>
							<CustomButton className='upload-btn'>
								{isUploading[source] ? (
									<div className='upload-spinner-wrapper'>
										<span>Uploading...</span>
										<Spinner className='upload-spinner' />
									</div>
								) : (
									// <PlusOutlined />
									<img src='./upload-default.png' alt='Upload list icon' />
								)}
							</CustomButton>
						</Upload>
					</Tooltip>
				</div>
			</div>
			<CustomModal
				isOpen={showSuccessModal}
				className='successModal modal_wrapper'
				onClose={() => setSuccessModal(false)}
				footer={[
					<CustomButton onClick={() => setSuccessModal(false)} key='btnClose' id='btnClose'>
						CLOSE
					</CustomButton>,
					<CustomButton
						className={`${sourceCall === 'Upload' ? 'hideBtn' : ''}`}
						onClick={downloadUpdatedList}
						type='primary'
						key='btndownload'
					>
						DOWNLOAD UPDATED LIST
					</CustomButton>,
				]}
			>
				<div className='success_modal'>
					<CheckCircleFilled style={{ color: '#03A803', fontSize: '50px' }} />
					<div className='success_modal_header'>Updated Successfully</div>
					<div className='success_modal_sub'>
						{source} List updated successfully, duplicate words (if any) have been removed to avoid
						errors.
					</div>
				</div>
			</CustomModal>
		</>
	);
};

UploadList.propTypes = {
	source: PropTypes.string,
	isUploading: PropTypes.object,
	setIsuploading: PropTypes.func,
	setIsFileUploaded: PropTypes.func,
	downloadTriggerPass: PropTypes.any,
	downloadTriggerBlock: PropTypes.any,
	getDictionaryFile: PropTypes.func,
	downloadFile: PropTypes.any,
};

export default UploadList;
