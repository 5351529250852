import { Modal } from 'antd';
import axios from 'axios';
import ReactDOM from 'react-dom';
import ROOT from '../src/client';
import App from './App';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import { saveAdminUserFlag, saveName, saveSessionFlag, saveTokenFlag } from './store/actions';
import { store, toObservable } from './store/store';

let username = '';
try {
	const state = toObservable(store);
	state.subscribe({
		onNext: (storeState) => {
			username = storeState?.storeProps?.username;
		},
	});
} catch (err) {
	console.error(err);
}

const confirmSessionExpiration = () => {
	if (store) {
		store.dispatch(saveTokenFlag(false));
		store.dispatch(saveSessionFlag(false));
		store.dispatch(saveAdminUserFlag(false));
		store.dispatch(saveName(''));
	}
};

const modalPopUp = (props) => {
	const { title, content, className, btnLabel, onOKClick } = props;
	return Modal.confirm({
		title: title,
		content: <strong>{content}</strong>,
		className: className,
	}).update({
		okText: btnLabel,
		onOk: onOKClick,
		okButtonProps: {
			className: 'btn blue-filed ok-btn',
		},
		cancelButtonProps: {
			style: { display: 'none' },
		},
		centered: true,
		maskClosable: false,
	});
};

axios.interceptors.request.use(
	(config) => {
		config.headers['Authorization'] = username;
		return config;
	},
	(error) => {
		return Promise.reject(error);
	},
);

axios.interceptors.response.use(undefined, (error) => {
	const originalRequest = error.config;
	if (
		error?.response?.status === 401 &&
		error?.response?.config?.url === `${ROOT}/api/access-token`
	) {
		confirmSessionExpiration();
		modalPopUp({
			title: 'Session Expired',
			content:
				'Your session has expired and requires page reload and re-login is recommended else application may get unresponsive.',
			className: 'SessionExpiration',
			btnLabel: 'Reload',
			onOKClick: () => window.location.reload(),
		});
	} else if (error?.response?.status === 401) {
		return new Promise(function (resolve, reject) {
			axios
				.get(`${ROOT}/api/access-token`)
				.then(() => {
					if (store) {
						store.dispatch(saveTokenFlag(true));
					}
					return resolve(axios(originalRequest));
				})
				.catch((err) => {
					console.error(err);
					reject(err);
				});
		});
	}
	if (
		error?.response?.status === 403 &&
		error?.response?.data?.message === 'User has been changed, please refresh the page.'
	) {
		confirmSessionExpiration();
		modalPopUp({
			title: 'Session Expired',
			content: 'Please login else application may get unresponsive.',
			className: 'SessionExpiration',
			btnLabel: 'Login',
			onOKClick: () => window.location.reload(),
		});
	}

	return Promise.reject({ ...error, intercepted: true });
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
