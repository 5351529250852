import { Col, Row } from 'antd';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSearcData } from '../../services';
import { saveDate, saveQueryStack } from '../../store/actions';
import CustomButton from '../Button';
import * as importHelper from '../ImportQuery/ImportHelper';
import * as helper from '../helper';
import './importQuery.scss';

const ImportFile = ({ handleCancel, setImportFile }) => {
	const dispatch = useDispatch();
	const { indexValue, indexAlias, aliasFlag, mappingImageKey } = useSelector(
		(store) => store.storeProps,
	);

	const [fileName, setFileName] = useState('');
	const [disableBtn, setDisableBtn] = useState(true);
	const [isBtnLoading, setIsBtnLoading] = useState(false);
	const [isValid, setIsValid] = useState({
		status: true,
		message: '',
		className: 'errorMessage',
	});

	const runQueryHandler = () => {
		try {
			if (fileName) {
				setIsBtnLoading(true);
				axios
					.get(`/api/get-query?fileName=${fileName}`)
					.then((res) => {
						if (res?.data?.query && res?.data?.statusCode === 200) {
							const fileString = res.data.query;
							if (fileString.groups && fileString.groups?.length && fileString.date) {
								const payload = {
									body: JSON.stringify({
										index: indexValue,
										alias: aliasFlag ? indexAlias : '',
										groups: fileString.groups,
										date: fileString.date,
										chart: false,
										mappingKey: mappingImageKey,
									}),
								};
								getSearcData(payload)
									.then((resp) => {
										setIsBtnLoading(false);
										if (resp?.data?.data) {
											importHelper.runQuery(fileString).then((parsedQuery) => {
												if (parsedQuery?.qStack && parsedQuery?.queryDate) {
													dispatch(saveDate(parsedQuery.queryDate));
													dispatch(saveQueryStack(parsedQuery.qStack));
													setImportFile(false);
												}
											});
										} else {
											setDisableBtn(true);
											setIsValid({
												status: false,
												message: 'Something went wrong. Please try later.',
												className: 'errorMessage',
											});
										}
									})
									.catch((error) => {
										if (
											error?.response?.data?.message &&
											error?.response?.data?.statusCode !== 401
										) {
											setIsBtnLoading(false);
											setDisableBtn(true);
											setIsValid({
												status: false,
												message:
													error.response.data.message === "Cannot read property 'type' of undefined"
														? 'Please make sure you have selected the correct elastic index.'
														: 'Something went wrong. Please try later.',
												className: 'errorMessage',
											});
										}
									});
							} else {
								setIsBtnLoading(false);
								setDisableBtn(true);
								setIsValid({
									status: false,
									message: 'Something went wrong. Please try later.',
									className: 'errorMessage',
								});
							}
						}
					})
					.catch((error) => {
						if (error?.response?.data?.message) {
							setIsBtnLoading(false);
							setIsValid({
								status: false,
								message: error.response.data.message,
								className: 'errorMessage',
							});
						}
					});
			}
		} catch (error) {
			console.error(error);
		}
	};

	const onFileUpload = (e) => {
		try {
			setIsValid({
				status: true,
				message: '',
				className: 'errorMessage',
			});
			let file = e.target.files[0];
			if (file && file.name && file.type === 'text/plain') {
				let reader = new FileReader();
				reader.onload = function (event) {
					if (event.target.result) {
						const fileData = helper.isValidJson(event.target.result);
						if (fileData && fileData.size && fileData.query) {
							const data = new FormData();
							data.append('file', file);
							axios
								.post('/api/import-query', data)
								.then((res) => {
									if (res?.data?.statusCode === 200) {
										setFileName(file.name);
										setDisableBtn(false);
										setIsValid({
											status: false,
											message: res?.data?.message ? res.data.message : '',
											className: 'successMessage',
										});
									}
								})
								.catch((error) => {
									setDisableBtn(true);
									if (error?.response?.data?.message) {
										setIsValid({
											status: false,
											message: error.response.data.message,
											className: 'errorMessage',
										});
									}
								});
						} else {
							setIsValid({
								status: false,
								message: 'File seems to be not correct.',
								className: 'errorMessage',
							});
						}
					}
				};
				reader.readAsText(file);
			} else {
				setDisableBtn(true);
				setIsValid({
					status: false,
					message: 'Please upload text file only.',
					className: 'errorMessage',
				});
			}
		} catch (error) {
			setDisableBtn(true);
			console.error(error);
		}
	};

	return (
		<div className='add-filters-section'>
			<Row className='ImportFileRow'>
				<Col span={24} className='importFileCol'>
					<h3>Import Query</h3>
				</Col>
				<Col span={12} className='importFileCol'>
					<label>Please upload text file only</label>
					<input id='fileInput' type='file' onChange={onFileUpload} />
					{!isValid.status && (
						<div id='infoDiv' className={isValid.className}>
							{isValid.message}
						</div>
					)}
					<div className='importFooter'>
						<CustomButton
							id='runQueryBtn'
							isDisabled={disableBtn}
							isLoading={isBtnLoading}
							onClick={runQueryHandler}
						>
							Run Query
						</CustomButton>
						<CustomButton onClick={() => handleCancel()}>Cancel</CustomButton>
					</div>
				</Col>
			</Row>
		</div>
	);
};

ImportFile.propTypes = {
	handleCancel: PropTypes.func,
	setImportFile: PropTypes.func,
};

export default ImportFile;
