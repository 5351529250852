import { CaretDownOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import { Col, Dropdown, Layout, Row, Tooltip, Typography } from 'antd';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import Bucket from '../Bucket/bucket';
import { SettingsMenu, UserProfileMenu } from '../Settings';
const { Header } = Layout;
const { Link } = Typography;

import './styles.scss';

const TopHeader = ({ bucket, setIsLoading }) => {
	const { username, reRedactionStatus } = useSelector((store) => store.storeProps);
	const [showUserProfileMenu, setShowUserProfileMenu] = useState(false);
	const [showSettingsMenu, setShowSettingsMenu] = useState(false);

	const userMenu = (
		<UserProfileMenu setIsLoading={setIsLoading} setVisibleMenu={setShowUserProfileMenu} />
	);

	const settingsMenu = <SettingsMenu setVisibleMenu={setShowSettingsMenu} />;

	return (
		<Header className='header'>
			<Row align='middle'>
				<Col span={6}>
					<div className='left-block-wrapper'>
						<Link href='/dashboard' target={reRedactionStatus ? '_blank' : '_self'}>
							<img src='./fortress-logo-login.svg' className='logo-dashboard' alt='FIQ' />
						</Link>
						<span>{'Privacy Enhanced Gateway'}</span>
					</div>
				</Col>

				<Col span={18}>
					<div className='rightBlock'>
						{bucket && (
							<div className='bucketWrapper'>
								<Bucket setIsLoading={setIsLoading} />
							</div>
						)}
						<div className='logout-wrapper'>
							<SettingOutlined className='settings-icon' style={{ fontSize: '18px' }} />
							<Dropdown
								visible={showSettingsMenu}
								onVisibleChange={() => setShowSettingsMenu(!showSettingsMenu)}
								overlay={settingsMenu}
								trigger={['click']}
							>
								<a
									href='true'
									className='ant-dropdown-link logout-link'
									onClick={(e) => e.preventDefault()}
								>
									<Tooltip placement='bottomRight' title={'Settings'}>
										<span className='menu-username' style={{ marginRight: '3px' }}>
											Settings
										</span>
									</Tooltip>
									<CaretDownOutlined />
								</a>
							</Dropdown>
						</div>

						<div
							className={`logout-wrapper ${reRedactionStatus ? 'disableProfile' : ''}`}
							title={reRedactionStatus ? 'Re-redaction is in progress' : ''}
						>
							<UserOutlined className='user-icon' style={{ fontSize: '18px' }} />
							<Dropdown
								visible={showUserProfileMenu}
								onVisibleChange={() => setShowUserProfileMenu(!showUserProfileMenu)}
								overlay={userMenu}
								trigger={['click']}
								disabled={reRedactionStatus}
							>
								<a
									href='true'
									className='ant-dropdown-link logout-link'
									onClick={(e) => e.preventDefault()}
								>
									<Tooltip placement='bottomRight' title={username}>
										<span className='menu-username' style={{ marginRight: '3px' }}>
											{username}
										</span>
									</Tooltip>
									<CaretDownOutlined />
								</a>
							</Dropdown>
						</div>
					</div>
				</Col>
			</Row>
		</Header>
	);
};

TopHeader.propTypes = {
	setIsLoading: PropTypes.func,
	bucket: PropTypes.bool,
};

export default TopHeader;
