import { CopyOutlined } from '@ant-design/icons';
import 'animate.css/animate.css';
import axios from 'axios';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import ROOT from '../../client';
import { createMC, createSignedUrl } from '../../services';
import '../../toastr.min.css';
import CustomButton from '../Button';
import customTost from '../Notification';

const ExportLogsButton = () => {
	const { envars } = useSelector((store) => store.storeProps);
	const [exportState, setExportState] = useState(false);
	const [logsState, setLogsState] = useState(false);
	const [exportUrl, setExportUrl] = useState('');

	const handleExportClick = () => {
		try {
			setExportState(true);
			axios
				.get(`${ROOT}/api/get-pod-logs`)
				.then((res) => {
					if (res) {
						const minioClient = createMC(envars);
						createSignedUrl(minioClient, 'system-files', 'logs.zip')
							.then((signedUrl) => {
								if (signedUrl) {
									setExportUrl(signedUrl);
									setLogsState(true);
								}
								setExportState(false);
							})
							.catch((err) => {
								customTost({
									type: 'error',
									message:
										err.code === 'NotFound'
											? 'An error occurred while generating logs. Please contact administrator.'
											: 'Something went wrong. Please try after sometime.',
								});
								setExportState(false);
							});
					}
				})
				.catch((err) => {
					console.error(err);
					customTost({
						type: 'error',
						message: 'Something went wrong. Please try after sometime.',
					});
					setExportState(false);
				});
		} catch (err) {
			console.error(err);
			customTost({
				type: 'error',
				message: 'Something went wrong. Please try after sometime.',
			});
			setExportState(false);
		}
	};
	const downloadLogs = () => {
		setLogsState(false);
	};

	return (
		<>
			{!exportState ? (
				<>
					{logsState ? (
						<a className='btnExport downloadLogs' onClick={downloadLogs} href={exportUrl}>
							<CopyOutlined /> Download Logs
						</a>
					) : (
						<CustomButton
							className='btnExport'
							type='primary'
							size='middle'
							onClick={handleExportClick}
							icon={<CopyOutlined />}
						>
							{'Export Logs'}
						</CustomButton>
					)}
				</>
			) : (
				<CustomButton className='btnExport' type='primary' size='middle' isLoading={exportState}>
					{'Generating Logs...'}
				</CustomButton>
			)}
		</>
	);
};

export default ExportLogsButton;
