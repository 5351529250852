import { Col, Radio, Row, Select, Slider } from 'antd';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ROOT from '../../client';
import CustomButton from '../Button';
import { Spinner } from '../Loader';
import customTost from '../Notification';
import * as helper from '../helper';
import './export.scss';

const { Option } = Select;

const ExportImages = (props) => {
	const { selectedBucket, carouselSlots, imageKeys, mappingImageKey, envars } = useSelector(
		(store) => store.storeProps,
	);
	const bucketName = selectedBucket?.bucketName ? selectedBucket.bucketName : '';
	const metaInfo = selectedBucket?.metaInfo ? selectedBucket.metaInfo : '';
	const { queryData, enableExport, setExportLogDisable, minioClient } = props;
	const [disable, setDisable] = useState(true);
	const [sliderDisable, setSliderDisable] = useState(false);
	// eslint-disable-next-line
	// const [tempToken,setTempToken] = useState("");
	const [folderName, setFolderName] = useState('');
	const [signedUrl, setSignedUrl] = useState('');
	const [imgOpt, setImgOpt] = useState('');
	const [imagesCount, setImagescount] = useState(1);
	const [maxCount, setMaxcount] = useState(
		imageKeys?.length && imageKeys[0] && imageKeys[0].length ? imageKeys[0].length : 50,
	);
	const [slot, setSlot] = useState(1);
	const [sliderRange, setSliderRange] = useState({
		start: 1,
		end:
			imageKeys?.length && imageKeys[0] && imageKeys[0].length
				? imageKeys[0].length <= 10
					? imageKeys[0].length
					: 10
				: 10,
	});
	const [validImgCount, setValidImgCount] = useState(true);

	const getCount = (start, end) => {
		if (start && end) {
			let imgCount = end - start;
			imgCount = imgCount === 0 ? 1 : imgCount + 1;
			return imgCount;
		}
	};

	useEffect(() => {
		try {
			if (imageKeys?.length) {
				setValidImgCount(true);
				let sv = slot - 1;
				if (!imageKeys[sv]) {
					sv = 0;
					setSlot(1);
				}
				const numRange = {
					start:
						imageKeys?.length && imageKeys[sv] && imageKeys[sv].length
							? imageKeys[sv].length <= 5
								? 1
								: sliderRange.start
							: 1,
					end:
						imageKeys?.length && imageKeys[sv] && imageKeys[sv].length
							? imageKeys[sv].length <= 10
								? imageKeys[sv].length
								: sliderRange.end
							: sliderRange.end,
				};
				setSliderRange(numRange);
				let imgCount = getCount(numRange.start, numRange.end);
				setImagescount(imgCount);
				setMaxcount(
					imageKeys?.length && imageKeys[sv] && imageKeys[sv].length
						? imageKeys[sv].length
						: maxCount,
				);
			}
		} catch (error) {
			console.error(error);
		}
		// eslint-disable-next-line
	}, [imageKeys]);

	const sliderHandler = (value) => {
		try {
			setImgOpt('');
			setDisable(true);
			setValidImgCount(true);
			if (value?.length) {
				const numRange = {
					start: value[0] ? value[0] : 1,
					end: value[1] ? value[1] : 10,
				};
				let imgCount = getCount(numRange.start, numRange.end);
				setImagescount(imgCount);
				setSliderRange(numRange);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const [loading, setLoading] = useState(false);

	useEffect(() => {
		try {
			setDisable(true);
			setImgOpt('');
			setSliderDisable(false);
		} catch (error) {
			console.error(error);
		}
	}, [enableExport]);

	// on selecting radio button for downloading images
	const onCheckedImageOpt = (event) => {
		try {
			setValidImgCount(true);
			if (imagesCount > 50) {
				setValidImgCount(false);
				return;
			}
			if (enableExport) {
				setImgOpt(event.target.value);
			} else {
				customTost({
					type: 'error',
					message: 'No images found.',
				});
			}
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		try {
			if (imgOpt && queryData.length) {
				exportImages();
			}
		} catch (error) {
			console.error(error);
		}
		// eslint-disable-next-line
	}, [imgOpt]);

	const slotHandler = (value) => {
		try {
			if (value) {
				const splitValue = value.indexOf(' ') !== -1 ? value.split(' ') : value;
				if (splitValue?.length && splitValue[1]) {
					value = Number(splitValue[1]);
					setSlot(value);
					value = value - 1;
					const numRange = {
						start: 1,
						end:
							imageKeys?.length && imageKeys[value] && imageKeys[value].length
								? imageKeys[value].length <= 10
									? imageKeys[value].length
									: 10
								: 10,
					};
					let imgCount = getCount(numRange.start, numRange.end);
					setImagescount(imgCount);
					setSliderRange(numRange);
					setMaxcount(
						imageKeys?.length && imageKeys[value] && imageKeys[value].length
							? imageKeys[value].length
							: 50,
					);
				}
			}
		} catch (error) {
			console.error(error);
		}
	};

	const exportImages = () => {
		try {
			if (mappingImageKey) {
				if (imgOpt && sliderRange) {
					if (slot) {
						if (queryData.length) {
							setExportLogDisable(true);
							setSliderDisable(true);
							setLoading(true);
							setDisable(true);
							let key_array = [];
							if (imageKeys?.length) {
								key_array = imageKeys[slot - 1] ? [...imageKeys[slot - 1]] : [];
							}
							if (key_array.length) {
								let imgArrExport = [];
								if (imgOpt === 'random') {
									const shuffled = key_array.sort(() => 0.5 - Math.random());
									imgArrExport = shuffled.slice(0, imagesCount);
								} else if (imgOpt === 'selectedrange') {
									imgArrExport = key_array.slice(sliderRange.start - 1, sliderRange.end);
								}

								const uniqueArrExport = helper.removeDuplicates(imgArrExport);
								const url = `${ROOT}/api/create_zip`;
								const payload = {
									imageKeys: JSON.stringify(uniqueArrExport),
									bucketName: bucketName,
									metaInfo: metaInfo,
									bucketFolderName: selectedBucket?.bucketFolderName
										? selectedBucket.bucketFolderName
										: '',
								};
								axios
									.post(url, payload)
									.then((resp) => {
										if (resp && resp.data && resp.data.statusCode === 200) {
											setFolderName(resp.data.folderName);
											refreshToken(resp.data.folderName);
										} else if (resp && resp.data && resp.data.statusCode === 400) {
											setLoading(false);
											setExportLogDisable(false);
											customTost({
												type: 'error',
												message: 'Something went wrong. Please try again',
											});
										}
									})
									.catch((error) => {
										console.error(error);
										setLoading(false);
										setSliderDisable(false);
										setExportLogDisable(false);
										customTost({
											type: 'error',
											message: error?.response?.data?.message
												? error?.response?.data?.message
												: 'Internal server error',
										});
										return;
									});
							} else {
								setSliderDisable(false);
								setExportLogDisable(false);
								setLoading(false);
								customTost({
									type: 'error',
									message: 'Something went wrong. Please try again.',
								});
							}
						} else {
							setSliderDisable(false);
							setExportLogDisable(false);
							customTost({
								type: 'error',
								message: 'Images not available. Please change date to export images.',
							});
						}
					} else {
						setSliderDisable(false);
						setExportLogDisable(false);
						customTost({
							type: 'error',
							message: 'Please select slot.',
						});
					}
				}
			} else {
				setExportLogDisable(false);
				customTost({
					type: 'error',
					message: 'Please select valid image key',
				});
				return;
			}
		} catch (error) {
			setSliderDisable(false);
			setExportLogDisable(false);
			setLoading(false);
			console.error(error);
		}
	};

	const [isImagesSaved, setIsImagesSaved] = useState(false);
	// saving images to minio after exporting
	const saveToMinio = () => {
		try {
			setIsImagesSaved(true);
			const url = `${ROOT}/api/save_to_bucket?zipFile=${folderName}&bucketName=${bucketName}&metaInfo=${metaInfo}&bucketFolderName=${
				selectedBucket?.bucketFolderName ? selectedBucket.bucketFolderName : ''
			}`;
			axios
				.get(url)
				.then((response) => {
					if (response && response.data) {
						if (response.data.statusCode === 200) {
							customTost({
								type: 'success',
								message: 'Saved to bucket successfully',
							});
						} else if (response.data.message) {
							customTost({
								type: 'info',
								message: 'Time to save images is over. Please try again!',
							});
						}
						setDisable(true);
						setImgOpt('');
						setIsImagesSaved(false);
					}
				})
				.catch((err) => {
					setIsImagesSaved(false);
					setDisable(true);
					setImgOpt('');
					console.error(err);
					customTost({
						type: 'error',
						message: 'Internal server error.',
					});
				});
		} catch (error) {
			setIsImagesSaved(false);
			console.error(error);
		}
	};

	const downloadImages = () => {
		setDisable(true);
		setImgOpt('');
	};

	const refreshToken = (folder) => {
		try {
			// set signed url state
			axios
				.get(
					`${ROOT}/api/getImages?zipFile=${folder}&bucketName=${bucketName}&metaInfo=${metaInfo}&bucketFolderName=${
						selectedBucket?.bucketFolderName ? selectedBucket.bucketFolderName : ''
					}`,
				)
				.then((resp) => {
					if (resp?.data?.statusCode === 200) {
						if (envars?.storageMechanism === 'minio') {
							if (resp?.data?.fileName && minioClient?.firstBucket) {
								minioClient.firstBucket
									.presignedGetObject(selectedBucket.bucketName, resp.data.fileName)
									.then((signedLink) => {
										if (signedLink) {
											setSignedUrl(signedLink);
											setDisable(false);
											setLoading(false);
											setSliderDisable(false);
										} else {
											setLoading(false);
											setSliderDisable(false);
											customTost({
												type: 'error',
												message: 'Something went wrong!',
											});
										}
									})
									.catch((error) => {
										console.error(error);
									});
							}
						} else {
							setSignedUrl(resp?.data?.signedUrl ? resp.data.signedUrl : '');
							setDisable(false);
							setLoading(false);
							setSliderDisable(false);
						}
					} else {
						setLoading(false);
						setSliderDisable(false);
						customTost({
							type: 'info',
							message: 'Something went wrong!',
						});
					}
					setExportLogDisable(false);
				})
				.catch((err) => {
					setLoading(false);
					setSliderDisable(false);
					setExportLogDisable(false);
					setImgOpt('');
					let errMsg = err?.response?.data?.message;
					if (err?.response?.status === 400) {
						customTost({
							type: 'error',
							message: errMsg ?? 'Something went wrong, please try again.',
						});
					} else {
						customTost({
							type: 'error',
							message: errMsg ?? 'Something went wrong, please try again.',
						});
					}
					console.error(err);
				});
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<>
			{queryData.length > 0 && (
				<>
					{loading ? (
						<div className='export loading'>
							<div className='load-icon-wrapper'>
								<Spinner />
								<span className='loading-label'>Please wait ...</span>
							</div>
						</div>
					) : (
						<>
							<Row className='export'>
								<Col span={24}>
									<h3>Export Images</h3>
								</Col>
							</Row>
							<Row>
								<Col span={24}>
									<label>
										Images Range: {sliderRange.start} to {sliderRange.end}{' '}
										<span className={`imgCount ${validImgCount ? '' : 'danger'}`}>
											Count: {imagesCount}
										</span>
									</label>

									<Slider
										range
										tooltipPlacement='bottom'
										className='slider'
										disabled={sliderDisable}
										value={[sliderRange.start, sliderRange.end]}
										max={maxCount}
										min={1}
										onChange={sliderHandler}
									/>
									<div className={`imageCountExtra ${validImgCount ? '' : 'danger'}`}>
										Max 50 images can be export.
									</div>
								</Col>
							</Row>
							<Row className='slotWrapper'>
								<Col span={8}>
									<label>Select Slot</label>
								</Col>
								<Col span={16}>
									<Select onSelect={slotHandler} value={`Slot ${slot}`} id='slots' showSearch>
										{carouselSlots?.length > 0 && (
											<>
												{carouselSlots.map((num) => {
													return (
														<Option key={num} value={`Slot ${num}`}>
															Slot {num}
														</Option>
													);
												})}
											</>
										)}
									</Select>
								</Col>
							</Row>
							<Row className='radioWrapper'>
								<Col span={8}>
									<label>Export Option</label>
								</Col>
								<Col span={16}>
									<Radio.Group
										className='exportRadioGroup'
										onChange={onCheckedImageOpt}
										value={imgOpt}
									>
										<Radio value='selectedrange'>Selected Range</Radio>
										<Radio value='random'>Random</Radio>
									</Radio.Group>
								</Col>
							</Row>
						</>
					)}

					<Row className='export-footer'>
						<Col span={18} offset={6} className='export-buttons'>
							<CustomButton
								isDisabled={disable}
								className='btnSave'
								isLoading={isImagesSaved}
								key='save'
								type='primary'
								onClick={saveToMinio}
							>
								Save to Bucket
							</CustomButton>
							<CustomButton isDisabled={disable} type='primary'>
								<a key='download' id='downloadImages' onClick={downloadImages} href={signedUrl}>
									Download
								</a>
							</CustomButton>
						</Col>
					</Row>
				</>
			)}
		</>
	);
};

ExportImages.propTypes = {
	queryData: PropTypes.array,
	enableExport: PropTypes.bool,
	setExportLogDisable: PropTypes.func,
	minioClient: PropTypes.object,
};

export default ExportImages;
