/* eslint-disable */

export const runQuery = (fileData) => {
	return new Promise((resolve, reject) => {
		try {
			if (fileData) {
				let queryObj = fileData;
				const filtersArr = [];
				let queryDate = '';
				if (queryObj?.groups?.length) {
					const grps = queryObj.groups;
					if (grps.length) {
						const groupsLength = grps.length;
						grps.forEach((arr, gi) => {
							if (arr.length) {
								let arrLength = arr.length;
								arr.forEach((queryProps, i) => {
									filtersArr.push({
										query: `${queryProps.key} ${queryProps.operator} ${queryProps.value}`,
										isChecked: true,
										field: queryProps.key,
										fieldValue: queryProps.value,
										operand:
											i === arrLength - 1 ? `${groupsLength - 1 === gi ? 'AND' : 'OR'}` : 'AND',
										group: false,
										allowOperand: true,
										braces: '',
										operator: queryProps.operator,
									});
								});
							}
						});
					}
				}
				if (queryObj.date) {
					const resDate = queryObj.date;
					let splitDate = '';
					if (resDate.value && resDate.value.indexOf('AND') !== -1) {
						splitDate = resDate.value.split('AND');
					}
					if (splitDate?.length) {
						queryDate = {
							dateRange: resDate,
							startDate: splitDate[0] ? splitDate[0].trim() : '',
							endDate: splitDate[1] ? splitDate[1].trim() : '',
						};
					}
				}
				if (filtersArr?.length && queryDate) {
					resolve({ qStack: filtersArr, queryDate: queryDate });
				} else {
					reject(false);
				}
			} else {
				reject(false);
			}
		} catch (error) {
			console.error(error);
			reject(false);
		}
	});
};
