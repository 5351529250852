import { ReloadOutlined } from '@ant-design/icons';
import { Progress } from 'antd';
import Text from 'antd/lib/typography/Text';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ROOT from '../../client';
import { bulkDeleteProps, saveDate, setDeleteMode } from '../../store/actions';

import CustomButton from '../Button';
import './stylesheet.scss';

const DELETE_PROGRESS_INTERVAL = 10000,
	DEFAULT_STATUS = 'Processing...',
	DEFAULT_STEP = 0;

const DeleteProgressModal = () => {
	const dispatch = useDispatch();
	const [status, setStatus] = useState(DEFAULT_STATUS);
	const [error, setError] = useState(false);
	const [step, setStep] = useState(DEFAULT_STEP);
	// const [versionConflict, setVersionConflict] = useState(false)
	const [isInterval, setIsInterval] = useState(true);
	const { deleteUUID, observedAtDetail } = useSelector((store) => store.storeProps);
	/* istanbul ignore next */
	const deleteProgress = () => {
		if (isInterval === false) return;

		axios
			.post(`${ROOT}/api/delete-images-progress`, { uuid: deleteUUID })
			.then((res) => {
				setError(false);
				if (res.data.progress[deleteUUID].status == 'Counting') {
					setStep(1);
					setStatus('Counting');
				} else if (res.data.progress[deleteUUID].status == 'Fetching') {
					setStep(2);
					setStatus('Fetching');
				} else if (res.data.progress[deleteUUID].status == 'Deleting logs') {
					setStep(3);
					setStatus('Deleting logs');
				} else if (res.data.progress[deleteUUID].status == 'Volume deletion') {
					setStep(4);
					setStatus('Volume deletion');
				} else if (res.data.progress[deleteUUID].status == 'Completed') {
					setStep(5);
					setStatus('Completed');
					// setVersionConflict(true)
				} else {
					setStep(DEFAULT_STEP);
					setStatus(DEFAULT_STATUS);
				}
			})
			.catch((err) => {
				setStep(5);
				setIsInterval(false);
				setError(true);
				if (err.response.data.message) return setStatus(err.response.data.message);
				setStatus('Something went wrong. Please contact the administrator.');
			});
	};

	useEffect(() => {
		const interval = setInterval(() => deleteProgress(), DELETE_PROGRESS_INTERVAL);

		if (step === 5) clearInterval(interval);

		return () => clearInterval(interval);
	}, [step === 5]);

	/* istanbul ignore next */
	const handleRefresh = () => {
		dispatch(setDeleteMode({ isDeleteModeOn: false }));
		dispatch(bulkDeleteProps({ isDeleteInProgressFlag: false }));
		dispatch(saveDate({ ...observedAtDetail }));
	};

	return (
		<div className='progress-modal'>
			<div className='progress-modal-box'>
				<Text className='underline'>Bulk Delete in Progress</Text>
				{step !== 0 && <Text className='small-text'>Step {step} of 5</Text>}
				{(step === 5 || error) && (
					<CustomButton
						className='addBucketBtn'
						icon={<ReloadOutlined />}
						onClick={handleRefresh}
						type='primary'
					>
						Refresh
					</CustomButton>
				)}
			</div>
			<div className='delete-modal-container'>
				<Progress percent={step * 20} status={error && 'exception'} />
				<Text>{status}</Text>
			</div>
		</div>
	);
};

export default DeleteProgressModal;
