import axios from 'axios';
import ROOT from '../../client';

export const getSuggestedValues = (
	field,
	value,
	mappingProps,
	indexValue,
	indexAlias,
	aliasFlag,
	observedAtDetail,
	filterOptions,
) => {
	return new Promise((resolve, reject) => {
		try {
			let searchField = field;
			if (mappingProps[field]?.fields && mappingProps[field]?.fields?.keyword?.type === 'keyword') {
				searchField = `${field}.keyword`;
			}
			const url = `${ROOT}/api/get-suggested-values`;
			const payload = {
				body: JSON.stringify({
					boolFilter: [],
					field: searchField,
					query: value,
					index: indexValue,
					alias: aliasFlag ? indexAlias : '',
					startRange: observedAtDetail?.startDate ? observedAtDetail?.startDate : '',
					endRange: observedAtDetail?.endDate ? observedAtDetail?.endDate : '',
				}),
			};
			axios.post(url, payload).then((resp) => {
				if (resp?.data?.data) {
					const data = resp.data.data;
					const resOpts = data?.aggregations?.byFieldName?.buckets
						? data.aggregations.byFieldName.buckets
						: [];
					const filterOpt = [];
					resOpts.forEach((item) => {
						const isValExsit = filterOptions.filter((optRow) => optRow.value === item.key);
						if (!isValExsit?.length) {
							filterOpt.push({
								value: item.key,
								label: item.key,
							});
						}
					});
					if (filterOpt?.length) {
						const newOpts = [...filterOptions, ...filterOpt];
						resolve(newOpts);
					}
				}
			});
		} catch (error) {
			reject([]);
			console.error(error);
		}
	});
};
