import { ExclamationCircleOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import CustomButton from '../Button';
import CustomModal from '../Modal';

const DeleteConfirmation = ({ modalState, setModalState, deleteAction, text }) => {
	return (
		<>
			<CustomModal
				isOpen={modalState.state}
				className='successModal modal_wrapper'
				width={460}
				footer={[
					<CustomButton
						onClick={() => setModalState({ state: false, record: '' })}
						key='btnClose'
						id='btnClose'
					>
						{'No, Cancel'}
					</CustomButton>,

					<CustomButton
						className='r-commit'
						onClick={() => {
							setModalState({ ...modalState, state: false });
							deleteAction();
						}}
						type='primary'
						key='btndownload'
					>
						Yes, Delete
					</CustomButton>,
				]}
			>
				<div className='success_modal'>
					<ExclamationCircleOutlined className='success-modal-icon' />
					<div className='success_modal_header'>{text}</div>
				</div>
			</CustomModal>
		</>
	);
};

DeleteConfirmation.propTypes = {
	modalState: PropTypes.any,
	setModalState: PropTypes.func,
	deleteAction: PropTypes.func,
	text: PropTypes.string,
};

export default DeleteConfirmation;
